<script setup lang="ts">
import { computed, ref, watch, provide, readonly, reactive, inject, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useLazyQuery } from '@vue/apollo-composable';
import { PlusIcon, CheckIcon } from '@heroicons/vue/solid';
import { ArchiveIcon, ShareIcon } from '@heroicons/vue/outline';
import uniqBy from 'lodash/uniqBy';
import dayjs from '@/lib/dayjs/config';
import { DprPeriods } from '@/utils/helpers/dprDates';
import OgDataTableControls from '@/components/organisms/OgDataTable/OgDataTableControls.vue';
import type { TDataTableFilter } from '@/components/organisms/OgDataTable/types';
import { type TmDataEntryQuery, UserRole, DataPointRequestStatusEnum } from '@/__generated__/types';
import useCurrentUser, { currentUser } from '@/utils/composables/useCurrentUser/useCurrentUser';
import useSuspendDataPointTypeMutation from '@/api/mutations/DataPointType/suspendDataPointType.mutation';
import { apolloClient } from '@/api/apollo/client';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import TmCustomDataPointTypeModal from '../TmCustomDataPointTypeModal/TmCustomDataPointTypeModal.vue';
import type { Project, TDataPointRequest, TQuestionDataTableItem } from '../../types';
import OgQuestionList from './OgQuestionList/OgQuestionList.vue';
import TM_DATA_ENTRY_QUERY from './TmDataEntry.query';
import OgSidePanel from './OgSidePanel.vue';
import MlRemoveValueModal from './OgQuestionList/MlRemoveValueModal.vue';
import MlDelegateQuestionModal from './OgQuestionList/MlDelegateQuestionModal.vue';

const route = useRoute();
const { t } = useI18n();
const { isAdminOrSuperAdmin } = useCurrentUser();

const props = defineProps<{
  projects: Project[],
}>();

const { result, refetch, load, loading } = useLazyQuery<TmDataEntryQuery>(TM_DATA_ENTRY_QUERY);

const handleLazyFetch = () => {
  if (loading.value === true) return;

  if (route.query.category) {
    if (result.value === undefined) {
      load(null, {
        categoryFilter: route.query.category,
        subcategoryFilter: route.query.subcategory,
        locationId: route.params.project,
        includeChilds: true,
      });
    } else {
      refetch({
        categoryFilter: route.query.category,
        subcategoryFilter: route.query.subcategory,
        locationId: route.params.project,
        includeChilds: true,
      });
    }
  }
};

onMounted(handleLazyFetch);

watch(() => [route.query.category, route.query.subcategory, route.params.project],
  (value, oldValue) => {
    if (
      oldValue
      && (
        value[0] !== oldValue[0]
        || value[1] !== oldValue[1]
        || value[2] !== oldValue[2]
      )
    ) {
      handleLazyFetch();
    }
  },
  { immediate: true });

const project = computed(() => props.projects.find(({ _id }) => _id === String(route.params.project)));
const isSidePanelHidden = inject('isSidePanelHidden', ref(false));

const entityTags = computed(() => result.value?.getEntityTags ?? []);
const dataPointRequests = computed(() => result.value?.getMyDataPointRequests ?? []);
const dataPointTypeOverrides = computed(
  () => result.value?.entityLocationSubcategorySummaries.map((location) => location.overrides)[0] ?? [],
);

const customQuestionnaireNames = computed(
  () => result.value?.getEntityCustomQuestionnaires.map((customQuestionnaire) => customQuestionnaire.name.split(' ').join('_')) ?? []);

const isCustomQuestionnaireCategoryOrSubcategory = computed(
  () => customQuestionnaireNames.value.includes(route.query?.category?.toString() ?? '')
  || customQuestionnaireNames.value.includes(route.query?.subcategory?.toString() ?? ''),
);

provide('dataPointTypeOverrides', readonly(dataPointTypeOverrides));
provide('entityTags', readonly(entityTags));

const headers = computed(() => [
  { text: t('Question'), value: 'question', sortable: true },
  { text: t('Tasks'), value: 'tasks', sortable: true },
  { text: t('Status'), value: 'status', sortable: true },
  { text: t('Due'), value: 'dueDate', sortable: true },
  { text: t(''), value: 'edit', sortable: false },
]);

const formatToItem = (dpr: TDataPointRequest): TQuestionDataTableItem => ({
  dpr,
  question: t(dpr.dataPointType.question),
  tasks: dpr.childs?.length,
  status: t(dpr.displayStatus),
  period: Math.abs(dayjs(dpr.from).diff(dpr.to, 'day')),
  dueDate: dayjs(dpr.dueDate).format('DD.MM.YYYY'),
  dueDateFromNow: dayjs(dpr.dueDate).fromNow(),
  nestedStatus: [...new Set([t(dpr.displayStatus), ...(dpr.childs?.map((child) => t(child.displayStatus)) ?? [])])],
  year: dpr.to ? dayjs(dpr.to).subtract(1, 'days').year() : dayjs().year(),
});

const items = computed<TQuestionDataTableItem[]>(
  () => dataPointRequests.value
  // remove all childs except childs that were assigned to the user without the parent
    .filter((dpr) => (
      !dpr.parent
      || !!dpr.delegations?.find((delegation) => delegation?.user._id === currentUser.value?._id))
      && !dataPointRequests.value.find(
        (parentDpr) => parentDpr.childs.find((child) => child._id === dpr._id)),
    )
    .map((dpr) => formatToItem(dpr)),
);

const filteredAndSortedItems = ref(items.value);
watch(items, () => { filteredAndSortedItems.value = items.value; });

const yearCriteria = computed(() => [...new Set(items.value.map((dpr) => dpr.year))].map((year) => year.toString()));

const customFilters = computed<TDataTableFilter[]>(() => [{
  name: t('status'),
  field: 'nestedStatus',
  criteria: Object.values(DataPointRequestStatusEnum).map(t),
  sortedOptions: true,
  comparison: (value, criteria: string[]) => {
    for (const crit of criteria) {
      if ((value as string[]).includes(crit)) return true;
    }
    return false;
  },
}, {
  name: t('Year'),
  field: 'year',
  sortedOptions: true,
  criteria: yearCriteria.value,
  comparison: (value: unknown, criteria: string[]) => {
    if (typeof value === 'number') {
      return criteria.includes(value.toString());
    }
    return false;
  },
},
{
  name: t('Refresh Interval'),
  field: 'period',
  sortedOptions: true,
  criteria: ['Once', 'Every day', 'Every week', 'Every month', 'Every quarter', 'Every 6 months', 'Every year'].map(t),
  comparison: (value: unknown, criteria: string[]) => {
    const numberValue = Number(value);

    if (!numberValue) {
      return criteria.includes(t('Once'));
    }

    switch (true) {
      case numberValue <= DprPeriods.DAILY:
        return criteria.includes(t('Every day'));
      case numberValue <= DprPeriods.WEEKLY:
        return criteria.includes(t('Every week'));
      case numberValue <= DprPeriods.MONTHLY:
        return criteria.includes(t('Every month'));
      case numberValue <= DprPeriods.QUARTERLY:
        return criteria.includes(t('Every quarter'));
      case numberValue <= DprPeriods.BI_ANNUAL:
        return criteria.includes(t('Every 6 months'));
      default:
        return criteria.includes(t('Every year'));
    }
  },
}]);

const activeConditionValues = reactive<Record<string, boolean>>({});
provide('activeConditionValues', activeConditionValues);

const activeCollapses = reactive<Record<string, boolean>>({});
provide('activeCollapses', activeCollapses);

const hoveredItem = ref('');
provide('hoveredItem', hoveredItem);

const commentBoxOpenItem = ref('');
provide('commentBoxOpenItem', commentBoxOpenItem);

const filePickerBoxOpenItem = ref('');
provide('filePickerBoxOpenItem', filePickerBoxOpenItem);

const textCopied = ref('');
provide('textCopied', textCopied);

const isCustomDataPointTypeModalRevealed = ref(false);

const loadedCategory = computed(() => project.value?.assignments.categories.find(
  (c) => c.category === route.query.category?.toString(),
));

const activeDpr = ref<undefined | TDataPointRequest>();
const activeDprState = computed(() => {
  const topParentDpr = filteredAndSortedItems.value
    .find((item) => item.dpr._id === activeDpr.value?._id || item.dpr._id === route.query.questionid?.toString())?.dpr;
  return topParentDpr ?? dataPointRequests.value
    .flatMap((item) => item.childs.flatMap((child) => [child, ...child.childs])) // return childs and subchilds in one array
    .find((child) => child?._id === activeDpr.value?._id || child._id === route.query.questionid?.toString());
});

watch(() => activeDprState.value, () => { activeDpr.value = activeDprState.value; });

//
// causes bug: when answering a historic question, the question collapses after each answer (annoying if there is another subquestion)
// const { OPEN, OVERDUE, REJECTED } = DataPointRequestStatusEnum;
// watch(() => filteredAndSortedItems.value, () => {
//   const activeDprId = route.query.questionid?.toString() ?? '';
//   const activeParent = dataPointRequests.value.find((item) => item.childs?.find((child) => child._id === activeDprId));
//   const activeParentId = dataPointRequests.value.find((item) => item.childs?.find((child) => child._id === activeDprId))?._id ?? '';
//   const activeGrandParent = dataPointRequests.value.find((item) => item.childs?.find((child) => child._id === activeParentId));
//   const activeGrandParentId = dataPointRequests.value.find((item) => item.childs?.find((child) => child._id === activeParent?._id))?._id ?? '';
//
//   activeConditionValues[activeParent?.value ?? ''] = true;
//   activeConditionValues[activeGrandParent?.value ?? ''] = true;
//
//   activeCollapses[activeDprId] = true;
//   activeCollapses[activeParent?._id ?? ''] = true;
//   activeCollapses[activeGrandParent?._id ?? ''] = true;
//
//   for (const key of Object.keys(activeCollapses)) {
//     if (![activeDprId, activeParentId, activeGrandParentId].includes(key)) {
//       delete activeCollapses[key];
//     }
//   }
//
//   if (!activeDprId && !activeParent && !activeGrandParent) {
//     const activeDprDefaultId = filteredAndSortedItems.value.find(
//       (item) => item.dpr.dataPointTypeFlat.subcategory === route.query.subcategory?.toString()
//       && ([OPEN, OVERDUE, REJECTED].includes(item.dpr.displayStatus)
//       || [OPEN, OVERDUE, REJECTED].includes(item.dpr.status)))?.dpr._id ?? '';
//     activeCollapses[activeDprDefaultId] = true;
//     activeDpr.value = filteredAndSortedItems.value.find((item) => item.dpr._id === activeDprDefaultId)?.dpr;
//   }
// }, { immediate: true });

const { mutate: suspendDPT, loading: suspendDPTLoading } = useSuspendDataPointTypeMutation();

const canToggleQuestions = ref(true);
provide('canToggleQuestions', canToggleQuestions);
const dataEntrySelectMode = ref(false);
provide('dataEntrySelectMode', dataEntrySelectMode);
const dataEntrySelectedItemIds = ref<string[]>([]);
provide('dataEntrySelectedItemIds', dataEntrySelectedItemIds);
const dataEntrySelectedItems = computed(
  () => filteredAndSortedItems.value.filter((item) => dataEntrySelectedItemIds.value.includes(item.dpr._id)),
);
watch(dataEntrySelectMode, () => {
  dataEntrySelectedItemIds.value = [];
});

const isRemoveValueModalRevealed = ref(false);
const isDelegateQuestionModalRevealed = ref(false);

function openDelegateQuestionModal() {
  isDelegateQuestionModalRevealed.value = true;
}

async function suspendSelectedDataPointTypes() {
  const selectedDataPointTypeIds = uniqBy(
    dataEntrySelectedItems.value.map(
      (item) => ({ locationId: item.dpr.location._id, dataPointTypeId: item.dpr.dataPointType._id }),
    ), 'dataPointTypeId');
  const suspenDataPointTypes = selectedDataPointTypeIds.map((item) => suspendDPT({
    locationId: item.locationId,
    dataPointTypeId: item.dataPointTypeId,
  }));

  await Promise.all(suspenDataPointTypes);

  apolloClient.cache.evict({ fieldName: 'getMyDataPointRequests' });
}

</script>

<template>
  <div v-if="project" class="px-6">
    <MlRemoveValueModal
      :isShown="isRemoveValueModalRevealed"
      :items="dataEntrySelectedItems"
      @cancel="isRemoveValueModalRevealed = false"
      @confirm="isRemoveValueModalRevealed = false"
    />

    <MlDelegateQuestionModal
      :isShown="isDelegateQuestionModalRevealed"
      :items="dataEntrySelectedItems"
      @cancel="isDelegateQuestionModalRevealed = false"
    />

    <Teleport to="#projectLayoutControls">
      <div class="flex items-end">
        <button
          v-if="route.query.category && route.query.subcategory"
          v-rolefrom="UserRole.MANAGER"
          variant="text"
          class="text-sm flex align-bottom items-end mb-1 text-primary font-medium cursor-pointer"
          @click.stop="isCustomDataPointTypeModalRevealed = true"
        >
          <PlusIcon class="h-4" />
          <span class="ml-1">
            {{ t('Add') }}
          </span>
        </button>
        <OgDataTableControls
          v-if="items.length > 0"
          v-model="filteredAndSortedItems"
          :headers="headers"
          :items="items"
          :customFilters="customFilters"
          showMultiSelect
          @updateSelectMode="dataEntrySelectMode = $event; $event === true
            ? isSidePanelHidden = false
            : isSidePanelHidden = isSidePanelHidden"
        />
      </div>
    </Teleport>
    <Teleport to="#projectSelectActions">
      <div v-if="dataEntrySelectMode" class="flex px-6 pt-1.5">
        <button
          class="cursor-pointer text-primary"
          @click="dataEntrySelectedItemIds.length
            ? dataEntrySelectedItemIds = []
            : dataEntrySelectedItemIds = filteredAndSortedItems.map((item) => item.dpr._id)"
        >
          {{ t('Select/Deselect all') }}
        </button>
        <div class="ml-auto flex">
          <AtButton
            v-rolefrom="UserRole.MANAGER"
            class="mr-3"
            variant="outline"
            :icon="ShareIcon"
            :disabled="!dataEntrySelectedItemIds.length"
            @click="openDelegateQuestionModal"
          >
            {{ t('Share') }}
          </AtButton>

          <div
            v-rolefrom="UserRole.ADMIN"
          >
            <AtButton
              :class="{ hidden: suspendDPTLoading }"
              :disabled="suspendDPTLoading || !dataEntrySelectedItemIds.length"
              :loading="suspendDPTLoading"
              :icon="ArchiveIcon"
              variant="outline"
              @click.stop="suspendSelectedDataPointTypes"
            >
              {{ t('Archive') }}
            </AtButton>
          </div>
        </div>
      </div>
    </Teleport>
    <section>
      <template v-if="filteredAndSortedItems.length > 0">
        <OgQuestionList
          :items="filteredAndSortedItems"
          :project="project"
          @questionArchived="refetch"
          @updateActiveQuestion="activeDpr = $event?.dpr"
        />
        <OgSidePanel
          :item="activeDpr"
          :isCustomQuestionnaireCategoryOrSubcategory="isCustomQuestionnaireCategoryOrSubcategory"
          @hide="isSidePanelHidden = true"
        />
      </template>
      <MlEmptyStateCard
        v-else-if="loadedCategory?.subcategories.length === 0"
        noButton
      >
        <p v-if="isAdminOrSuperAdmin" class="text-center text-sm text-gray-500">
          {{ t('This data was not requested by your company. If you want to include this topic, click on the settings button next to the topic in the left side menu.') }}
        </p>
        <p v-else class="text-center text-sm text-gray-500">
          {{ t('Your company does not request this data. Please contact your administrator if you want to incorporate this topic.') }}
        </p>
      </MlEmptyStateCard>
      <MlEmptyStateCard
        v-else-if="!loading && undefined !== result"
        :title="t('There is no template for this Scope 3 category')"
        noButton
      >
        <CheckIcon class="h-8 w-8" />
        <template #description>
          <p class="mt-1 text-center text-sm text-gray-500">
            {{ t('While we work on this, you can create custom data points and request this information.') }}
          </p>
        </template>
      </MlEmptyStateCard>
      <TmCustomDataPointTypeModal
        v-if="isCustomDataPointTypeModalRevealed"
        crud="create"
        @close="isCustomDataPointTypeModalRevealed = false"
      />
    </section>
  </div>
</template>
