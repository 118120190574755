<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { DownloadIcon } from '@heroicons/vue/solid';
import { DataPointTypeEmissionTypeEnum, PdfReportTypeEnum, ReportingFrameworkEnum } from '@/__generated__/types';
import { featureFlags } from '@/lib/flagsmith';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtTooltipIcon from '@/components/atoms/AtTooltipIcon.vue';
import MlTabs, { type Tab } from '@/components/molecules/MlTabs/MlTabs.vue';
import { useCsvExport, usePdfExport } from './composables';

export type DataReadyEvent = {
  type: 'carbonFootprint' | 'standard' | 'qualitative';
  data: {
    dataPointTypeIds: string[];
    dataPointTypeNames: string[];
    dateFrom: string;
    dateTo: string;
    project: string;
    categories: string[];
    workspaces: string[];
    scope2EmissionTypeExcluded?: DataPointTypeEmissionTypeEnum.SCOPE_2_LOCATION_BASED
    | DataPointTypeEmissionTypeEnum.SCOPE_2_MARKET_BASED;
  };
};
export type SingleStandardTableExportEvent = {
  dataPointTypeId: string;
  dataPointTypeName: string;
};

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const { currentUser } = useCurrentUser();

const adminMode = inject('adminMode', computed(() => false));

const carbonFootprintTabValue = computed(() => adminMode.value ? 'adminResultsCarbonFootprintView' : 'resultsCarbonFootprintView');
const standardTabValue = computed(() => adminMode.value ? 'adminResultsStandardView' : 'resultsStandardView');
const qualitativeTabValue = computed(() => adminMode.value ? 'adminResultsQualitativeView' : 'resultsQualitativeView');

const activeTab = computed(() => String(route.name));
const tabs = computed(() => {
  const numericalTab = { name: adminMode.value ? t('Numerical results') : t('All data'), value: standardTabValue.value };
  const qualitativeTab = { name: t('Text-based results'), value: qualitativeTabValue.value };
  const carbonFootprintTab = { name: t('Carbon footprint'), value: carbonFootprintTabValue.value };

  if (featureFlags.emissions && adminMode.value) {
    return [numericalTab, carbonFootprintTab, qualitativeTab];
  }
  if (featureFlags.emissions) {
    return [numericalTab, carbonFootprintTab];
  }
  if (adminMode.value) {
    return [numericalTab, qualitativeTab];
  }
  return [numericalTab];
});

const isExportingAllCsv = ref(false);
const carbonFootprintData = ref<DataReadyEvent['data']>();
const standardData = ref<DataReadyEvent['data']>();
const qualitativeData = ref<DataReadyEvent['data']>();

const {
  loading: isCsvLoading,
  exportFile: exportAsCsvFile,
} = useCsvExport();
const {
  loading: isPdfLoading,
  exportFile: exportAsPdfFile,
} = usePdfExport();

const hasResultsExport = computed(() => {
  if (currentUser.value?.entity.reportingFrameworks.includes(ReportingFrameworkEnum.DNK)) {
    return featureFlags.resultsPDFReportDNK;
  }

  if (currentUser.value?.entity.reportingFrameworks.includes(ReportingFrameworkEnum.ESRS)) {
    return featureFlags.resultsPDFReportESRS;
  }

  if (currentUser.value?.entity.reportingFrameworks.includes(ReportingFrameworkEnum.GRI)) {
    return featureFlags.resultsPDFReportGRI;
  }

  return false;
});

function handleExportAllAsCsv() {
  isExportingAllCsv.value = true;

  if (activeTab.value === 'adminResultsCarbonFootprintView' || activeTab.value === 'resultsCarbonFootprintView') {
    if (carbonFootprintData.value) {
      exportAsCsvFile(
        carbonFootprintData.value.dataPointTypeIds,
        carbonFootprintData.value.dataPointTypeNames,
        carbonFootprintData.value.dateFrom,
        carbonFootprintData.value.dateTo,
        carbonFootprintData.value.project,
      );
    }
  } else if (standardData.value) {
    exportAsCsvFile(
      standardData.value.dataPointTypeIds,
      standardData.value.dataPointTypeNames,
      standardData.value.dateFrom,
      standardData.value.dateTo,
      standardData.value.project,
    );
  }
}

function handleExportAllAsPdf() {
  if (activeTab.value === 'adminResultsCarbonFootprintView' || activeTab.value === 'resultsCarbonFootprintView') {
    if (carbonFootprintData.value) {
      exportAsPdfFile(
        PdfReportTypeEnum.CARBON_FOOTPRINT,
        carbonFootprintData.value.dataPointTypeIds,
        carbonFootprintData.value.dateFrom,
        carbonFootprintData.value.dateTo,
        carbonFootprintData.value.categories,
        carbonFootprintData.value.project,
        carbonFootprintData.value.workspaces,
        carbonFootprintData.value.scope2EmissionTypeExcluded,
      );
    }
  } else if (activeTab.value === 'adminResultsQualitativeView') {
    if (qualitativeData.value) {
      exportAsPdfFile(
        PdfReportTypeEnum.QUALITATIVE,
        qualitativeData.value.dataPointTypeIds,
        qualitativeData.value.dateFrom,
        qualitativeData.value.dateTo,
        qualitativeData.value.categories,
        qualitativeData.value.project,
        qualitativeData.value.workspaces,
      );
    }
  } else if (standardData.value) {
    exportAsPdfFile(
      PdfReportTypeEnum.STANDARD,
      standardData.value.dataPointTypeIds,
      standardData.value.dateFrom,
      standardData.value.dateTo,
      standardData.value.categories,
      standardData.value.project,
      standardData.value.workspaces,
    );
  }
}

function handleDataReady({ type, data }: DataReadyEvent) {
  if (type === 'carbonFootprint') {
    carbonFootprintData.value = data;
  } else if (type === 'qualitative') {
    qualitativeData.value = data;
  } else {
    standardData.value = data;
  }
}

function handleExportSingleStandardTableAsCsv(event: SingleStandardTableExportEvent) {
  if (standardData.value) {
    exportAsCsvFile(
      [event.dataPointTypeId],
      [event.dataPointTypeName],
      standardData.value.dateFrom,
      standardData.value.dateTo,
      standardData.value.project,
    );
  }
}
</script>

<template>
  <div class="pt-5 px-6 grid">
    <div class="sticky top-0 z-10 bg-white pb-4">
      <div class="flex items-center">
        <div class="flex-1">
          <h1 class="text-lg font-medium leading-6">
            {{ t('Results') }}
          </h1>
        </div>
        <div class="flex flex-initial items-center space-x-2">
          <AtButton
            v-if="!adminMode"
            :loading="isExportingAllCsv && isCsvLoading"
            :icon="DownloadIcon"
            @click.stop="handleExportAllAsCsv"
          >
            {{ t('Export as Excel') }}
          </AtButton>
          <AtButton
            v-if="hasResultsExport"
            variant="outline"
            :icon="DownloadIcon"
            :loading="isPdfLoading"
            @click.stop="handleExportAllAsPdf"
          >
            {{ t('Export as PDF') }}
          </AtButton>
        </div>
      </div>
      <p class="text-sm text-gray-500">
        {{ t('View all data entry results and download report-ready sustainability data.') }}
      </p>
    </div>

    <MlTabs
      :modelValue="activeTab"
      :tabs="tabs"
      isEdgeToEdge
      @update:modelValue="router.push({ name: $event.value, query: $event.query })"
    >
      <template #default="{ tab }">
        <span class="flex items-center space-x-2">
          <span>{{ t((tab as Tab).name) }}</span>
          <AtTooltipIcon
            v-if="(tab as Tab).value === standardTabValue && standardTabValue === 'resultsStandardView'"
            :tooltip="t('The results of all of your datapoints are listed here.')"
            :delay="0"
          />
        </span>
      </template>
    </MlTabs>

    <section class="pt-6">
      <router-view @dataReady="handleDataReady" @export="handleExportSingleStandardTableAsCsv" />
    </section>
  </div>
</template>
