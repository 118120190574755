<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import OgActionPanel from '@/components/organisms/OgActionPanel/OgActionPanel.vue';
import { type PgTaxonomyAssessmentFinaliseQuery, type PgTaxonomyAssessmentFinaliseQueryVariables } from '@/__generated__/types';
import OgAssessmentFinishingProgress from '../OgAssessmentFinishingProgress.vue';
import OgProjectTable from '../../PgResults/OgProjectTable.vue';
import PG_TAXONOMY_ASSESSMENT_FINALISE_QUERY from './PgTaxonomyAssessmentFinalise.query';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const { result } = useQuery<
  PgTaxonomyAssessmentFinaliseQuery,
  PgTaxonomyAssessmentFinaliseQueryVariables
>(PG_TAXONOMY_ASSESSMENT_FINALISE_QUERY, { _id: String(route.params.projectId) });

const isResultPanelOpen = ref(false);

const next = () => {
  router.push({ name: 'taxonomyResults', params: { projectId: route.params.projectId } });
};

const prev = () => {
  router.push({ name: 'taxonomyAssessmentDocumentsUpload' });
};

const calculateResult = () => {
  isResultPanelOpen.value = true;
};
const project = computed(() => result.value?.getTaxonomyProject ?? {} as PgTaxonomyAssessmentFinaliseQuery['getTaxonomyProject']);
</script>

<template>
  <div class="m-auto">
    <OgAssessmentFinishingProgress :x="5" :y="5">
      {{ t('Congratulations! You completed the Taxonomy assessment!') }}
    </OgAssessmentFinishingProgress>
  </div>

  <div class="flex justify-between w-full gap-4">
    <AtButton class="my-4 float-right" variant="outline" @click="prev">
      {{ t('Back') }}
    </AtButton>

    <div class="flex items-center gap-4">
      <AtButton class="my-4 float-right" @click="calculateResult">
        {{ t('Calculate results') }}
      </AtButton>

      <AtButton class="my-4 float-right" @click="next">
        {{ t('Finish') }}
      </AtButton>
    </div>
  </div>

  <OgActionPanel
    v-if="project.assessments"
    :isOpened="isResultPanelOpen"
    hasPaddingX
    is4xlPanel
    @closePanel="isResultPanelOpen = false"
  >
    <template #title>
      {{ t('You have completed the Taxonomy calculation. According to the information provided, here is your overview:') }}
    </template>
    <div class="flex-1 mt-6">
      <OgProjectTable :project="project" />
    </div>
    <div class="flex justify-end gap-4">
      <AtButton @click="next">
        {{ t('Finish') }}
      </AtButton>
    </div>

    <!-- <div class="">
      <AtButton
        :loading="rejectLoading"
        :error="!!rejectError"
        @click="withHandler(rejectMutation, selectedDataPointRequest!._id)"
      >
        {{ t('Download as PDF') }}
      </AtButton>
      <AtButton
        class="ml-4"
        data-cy="approveDataPointRequest"
        :loading="approveLoading"
        :error="!!approveError"
        @click="isResultPanelOpen = false"
      >
        {{ t('Close') }}
      </AtButton>
    </div> -->
  </OgActionPanel>
</template>
