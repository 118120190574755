<script lang="ts">
import { computed } from 'vue';

enum ProgressStatus {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default',
}

</script>

<script setup lang="ts">
interface TProps {
  percent?: number;
  status?: `${ProgressStatus}`;
  showPercentNumber?: boolean;
  foreground?: boolean;
  showProgressionColors?: boolean;
  percentDecimals?: boolean;
  size?: 'sm' | 'md' | 'lg';
  contrast?: 'low' | 'high';
}

const props = withDefaults(defineProps<TProps>(), {
  percent: undefined,
  status: ProgressStatus.DEFAULT,
  showProgressionColors: false,
  percentDecimals: false,
  size: 'sm',
  contrast: 'low',
});

const classObject = computed(() => ({
  'progress h-1': true,
  'bg-gray-50': props.contrast === 'low',
  'bg-gray-200': props.contrast === 'high',
  'h-1': props.size === 'sm',
  'h-2': props.size === 'md',
  'h-6': props.size === 'lg',
  'progress-primary text-blue-50': props.status === ProgressStatus.DEFAULT,
  'progress-warning text-yellow-50': props.status === ProgressStatus.WARNING,
  'progress-error text-red-100': props.status === ProgressStatus.ERROR,
  'progress-success text-green-800': props.status === ProgressStatus.SUCCESS,
  'progress-success': props.percent && props.percent === 100 && props.showProgressionColors,
  'progress-warning': props.percent && props.percent >= 50 && props.percent < 100 && props.showProgressionColors,
  'progress-error': props.percent && props.percent >= 0 && props.percent < 50 && props.showProgressionColors,
  'bg-transparent': props.foreground,
}));
</script>

<template>
  <div
    class="flex items-center justify-between gap-2"
    :class="{ 'absolute left-0 top-0 z-20 w-full': props.foreground }"
  >
    <progress
      :class="classObject"
      :value="props.percent"
      max="100"
      v-bind="$attrs"
    />
    <div
      v-if="props.showPercentNumber"
      class="shrink-0 w-11 text-2xs text-center font-medium leading-none"
    >
      <template v-if="props.percentDecimals">
        {{ props.percent?.toFixed(2) }}%
      </template>
      <template v-if="!props.percentDecimals">
        {{ props.percent?.toFixed(0) }}%
      </template>
    </div>
  </div>
</template>
