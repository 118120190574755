import saveFile from './saveFile';

const writeReport = (encodedData: string, lastName: string | null | undefined, dataPointTypeNames: string[]) => {
  saveFile(encodedData, `esg_report${lastName ? `_${lastName}` : ''}${dataPointTypeNames.length === 1 ? `_${dataPointTypeNames[0]}` : ''}.xlsx`, 'application/vnd.ms-excel;charset=utf-8');
};
const generateReport = (
  encodedReport: string,
  lastName: string | null | undefined,
  dataPointTypeNames: string[] = [],
) => {
  writeReport(encodedReport, lastName, dataPointTypeNames);
};

export default generateReport;
