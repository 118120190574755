<script setup lang="ts">
import { CheckIcon } from '@heroicons/vue/outline';
import { useI18n } from 'vue-i18n';

export type TItem = {
  label?: string, value: string, childValue?: string, childs?: TItem[], [key: string]: unknown, isCompleted?: boolean, class?: string
};

const props = defineProps<{
  title?: string,
  items: TItem[],
  modelValue?: TItem,
  childModelValue?: TItem,
  scrollable?: boolean,
  allExpanded?: boolean;
  compact?: boolean;
}>();

const emit = defineEmits<TEmits>();
type TEmits = {
  (e: 'update:modelValue', selectedItem: TItem): void
}

const { t } = useI18n();
</script>

<template>
  <div
    :class="{
      'overflow-y-auto': props.scrollable,
      '!w-64': !compact,
      'w-[140px] py-3 pl-3 pr-2 pt-5': compact,
    }"
  >
    <h2
      v-if="props.title"
      class="mb-7 ml-2 mt-5 text-lg font-medium leading-6 text-gray-900 sm:truncate"
    >
      {{ t(props.title) }}
    </h2>
    <slot />
    <nav
      class="flex flex-col overflow-x-hidden"
      :class="{ 'overflow-y-auto': props.scrollable }"
      data-cy="MlSidebarTabs-items"
    >
      <template
        v-for="item in items"
        :key="item.value"
      >
        <slot
          name="tab"
          v-bind="{ item }"
        >
          <button
            class="rounded-md py-2 pl-4 text-left text-gray-900 hover:text-primary relative"
            :class="{
              'text-primary': item.value === props.modelValue?.value && (!item.childs || !props.childModelValue?.value),
              'text-teal-600 flex justify-between': item.isCompleted,
              'items-center': compact,
              'pr-3': !compact && item.isCompleted,
              'pr-6': compact && !item.isCompleted,
              ...(item.class) ? { [item.class]: true } : {},
            }"
            type="submit"
            :title="t(item.label ?? item.value)"
            @click="emit('update:modelValue', { value: item.value, childValue: item.childs?.length ? item.childs[0].value : '' })"
          >
            <slot
              name="tabContent"
              v-bind="{ item }"
            >
              <p :class="{ 'text-sm break-words': compact, 'line-clamp-2 mr-10 break-words': !compact }">
                {{ t(item.label ?? item.value) }}
              </p>
              <slot name="tabContentAfterLabel" v-bind="{ item }" />

              <MlSidebarTabs
                v-if="item.childs && (item.value === props.modelValue?.value || props.allExpanded)"
                :items="item.childs"
                :modelValue="props.childModelValue"
                class="py-0 border-l ml-2 mt-2"
                @click.stop=""
                @update:modelValue="(subcategory) => emit('update:modelValue', { value: item.value, childValue: subcategory.value })"
              />
            </slot>
            <CheckIcon
              v-if="item.isCompleted"
              class="h-5 w-5"
            />
          </button>
        </slot>
      </template>
    </nav>
  </div>
</template>
