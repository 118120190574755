<script setup lang="ts">
import { computed, type ComputedRef } from 'vue';
import { useI18n } from 'vue-i18n';
import sortBy from 'lodash/sortBy';
import { ArrowDownIcon, ArrowUpIcon, ArrowRightIcon } from '@heroicons/vue/solid';
import type { QUnitType } from '@/lib/dayjs/config';
import dayjs from '@/lib/dayjs/config';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import { DataPointTypeTargetActionEnum, DataPointTypeValueUnitEnum, DataPointTypeRefreshIntervalEnum } from '@/__generated__/types';
import AtBadge from '@/components/atoms/AtBadge.vue';
import AtDataPointRequestDate from '@/components/atoms/AtDataPointRequestDate.vue';
import AtValueUnit from '@/components/atoms/AtValueUnit.vue';
import type { DataPointsByProject } from './types';

type TProps = {
  dataPointsByProject: DataPointsByProject
  disabled?: boolean
}

const props = withDefaults(defineProps<TProps>(), {
  disabled: false,
});

const { t } = useI18n();
const { formatNumber } = useFormatNumber();

const dataPointsSorted = computed(() => sortBy(props.dataPointsByProject.dataPoints, ['from']).reverse());
const hasDataPoints = computed(() => !!dataPointsSorted.value.length);
const hasPreviousDataPoints = computed(() => dataPointsSorted.value.length > 1);
const currentDataPoint = computed(() => hasDataPoints.value ? dataPointsSorted.value[0] : null);
const previousDataPoint = computed(() => hasPreviousDataPoints.value ? dataPointsSorted.value[1] : currentDataPoint.value);

const dataPointDifferential = computed(() => (currentDataPoint.value?.value as number) - (previousDataPoint.value?.value as number));
const dataPointDifferentialPercentage = computed(
  () => (dataPointDifferential.value as number * 100) / (previousDataPoint.value?.value as number),
);

const badgeType = computed(() => {
  if (currentDataPoint.value?.dataPointType.targetAction === DataPointTypeTargetActionEnum.DECREASE) {
    if (dataPointDifferential.value > 0) return 'down';
    if (dataPointDifferential.value === 0) return 'right';
    return 'up';
  }
  if (dataPointDifferential.value > 0) return 'up';
  if (dataPointDifferential.value === 0) return 'right';
  return 'down';
});

const substractDateUnitIfNoPreviousDataPoint: ComputedRef<QUnitType> = computed(() => {
  switch (currentDataPoint.value?.dataPointType.refreshInterval) {
    case DataPointTypeRefreshIntervalEnum.MONTHLY: return 'month';
    case DataPointTypeRefreshIntervalEnum.QUARTERLY: return 'quarter';
    case DataPointTypeRefreshIntervalEnum.HALF_YEARLY: return 'month';
    case DataPointTypeRefreshIntervalEnum.YEARLY: return 'year';
    default: return 'day';
  }
});

</script>
<template>
  <div>
    <AtHeading
      type="h5"
      :class="{ 'text-gray-200': disabled }"
    >
      {{ props.dataPointsByProject.project?.name }}
    </AtHeading>
    <div class="mx-auto flex items-center gap-x-0.5 pb-2.5 pt-4 text-[10px]">
      <span
        class="text-4xl"
        :class="{
          'text-gray-200': disabled,
          'text-gray-400': !currentDataPoint?.value,
        }"
      >
        {{ currentDataPoint?.value ? formatNumber(currentDataPoint?.value) : '––' }}
      </span>
      <AtValueUnit
        v-if="currentDataPoint?.dataPointType.valueUnit"
        class="ml-1"
        :class="{ 'text-gray-200': disabled }"
        :valueUnit="currentDataPoint.dataPointType.valueUnit"
        :valueUnitDivisor="currentDataPoint.dataPointType.valueUnitDivisor"
      />
    </div>
    <div
      class="flex flex-wrap items-center justify-start text-xs font-light leading-5"
      :class="{ 'text-gray-200': disabled }"
    >
      <AtBadge
        v-if="hasPreviousDataPoints"
        :type="badgeType"
        size="xs"
        class="mr-1"
        :class="{ 'bg-gray-200 text-gray-200': disabled }"
      >
        <ArrowUpIcon
          v-if="dataPointDifferential > 0"
          class="w-3"
        />
        <ArrowRightIcon
          v-else-if="dataPointDifferential === 0"
          class="w-3"
        />
        <ArrowDownIcon
          v-else
          class="w-3"
        />
        <span class="pl-1">
          {{ formatNumber(dataPointDifferentialPercentage) }}
        </span>
        <AtValueUnit
          class="ml-1"
          :valueUnit="DataPointTypeValueUnitEnum.PERCENT"
        />
      </AtBadge>

      <span
        v-if="previousDataPoint"
        class="mr-1 text-gray-400"
        :class="{ 'text-gray-200': disabled }"
      >
        {{ t('vs ') }}
        <AtDataPointRequestDate
          v-if="previousDataPoint.from === currentDataPoint?.from"
          :from="dayjs(currentDataPoint?.from).subtract(1, substractDateUnitIfNoPreviousDataPoint).toString()"
          :to="currentDataPoint?.from"
        />
        <AtDataPointRequestDate
          v-else
          :from="previousDataPoint?.from"
          :to="previousDataPoint?.to"
        />
        {{ t(':') }}
      </span>
      <span
        v-if="hasPreviousDataPoints"
        class="text-gray-900 flex"
        :class="{ 'text-gray-200': disabled }"
      >
        {{ formatNumber(previousDataPoint?.value) }}
        <AtValueUnit
          v-if="previousDataPoint?.dataPointType.valueUnit && hasPreviousDataPoints"
          class="ml-1 min-w-fit"
          :valueUnit="previousDataPoint.dataPointType.valueUnit"
          :valueUnitDivisor="previousDataPoint.dataPointType.valueUnitDivisor"
        />
      </span>
      <span
        v-else
        class="min-w-fit text-gray-400"
      >
        {{ t('no data yet') }}
      </span>
    </div>
  </div>
</template>
