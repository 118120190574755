<script setup lang="ts">
import { computed, nextTick, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import { useVuelidate } from '@vuelidate/core';
import { ArrowNarrowLeftIcon } from '@heroicons/vue/solid';
import {
  ComparisonOperatorEnum,
  type PgKpiQuery,
  type PgKpiQueryVariables,
  ComparisonValueTypeEnum } from '@/__generated__/types';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useUpdateEntityKPIMutation from '@/api/mutations/EntityKPI/updateEntityKpi.mutation';
import ESGGoal from '@/components/atoms/ESGGoal.vue';
import AtBadge from '@/components/atoms/AtBadge.vue';
import OgKPIForm from '../PgGoalsOverview/TmCreateKPITopDownModal/OgKPIForm/OgKPIForm.vue';
import PG_KPI_QUERY from './PgKPI.query';
import OgComments from './OgComments/OgComments.vue';

const { t } = useI18n();
const route = useRoute();
const v = useVuelidate();

const kpiQueryVariables = {
  goal: route.params.goal as string,
  target: route.params.target as string,
  kpi: route.params.kpi as string,
};

const { mutate: updateKPI, loading: loadingMutation } = useUpdateEntityKPIMutation(
  { refetchQueries: [{ query: PG_KPI_QUERY, variables: kpiQueryVariables }] },
);
const { result, loading, refetch } = useQuery<PgKpiQuery, PgKpiQueryVariables>(PG_KPI_QUERY, kpiQueryVariables);

const target = computed(() => result.value?.getTarget);
const targets = computed(() => [
  ...(result.value?.getEntityKPI.dataPointType.suggestedTargets.length ? result.value.getEntityKPI.dataPointType.suggestedTargets : []),
  ...(result.value?.getTarget ? [result.value?.getTarget] : [])]);
const goal = computed(() => result.value?.getGoal);
const goals = computed(() => Object.values(targets.value?.reduce((acc, curr) => {
  if (curr.goal.name) {
    acc[curr.goal.name] = curr.goal;
  }

  return acc;
}, {} as Record<string, PgKpiQuery['getEntityKPI']['dataPointType']['suggestedTargets'][number]['goal']>,
) ?? {}));
const kpi = computed(() => result.value?.getEntityKPI);

const kpiFormData = reactive({
  description: '',
  user: '',
  comparisonOperator: '' as ComparisonOperatorEnum,
  comparisonValue: 0,
  comparisonValueType: '' as ComparisonValueTypeEnum,
  startingDate: null as Date | null,
  startingValue: undefined as number | undefined,
  dueDate: null as Date | null,
});

const resetFormData = () => {
  kpiFormData.description = kpi.value?.description ?? '';
  kpiFormData.user = kpi.value?.user._id ?? '';
  kpiFormData.comparisonOperator = kpi.value?.comparisonOperator ?? '' as ComparisonOperatorEnum;
  kpiFormData.comparisonValue = kpi.value?.comparisonValue ?? 0;
  kpiFormData.comparisonValueType = kpi.value?.comparisonValueType ?? '' as ComparisonValueTypeEnum;
  kpiFormData.startingDate = kpi.value?.startingDate;
  kpiFormData.startingValue = kpi.value?.startingValue ?? undefined;
  kpiFormData.dueDate = kpi.value?.dueDate;
};

const updateChartRange = async () => {
  if (!kpiFormData.startingDate || !kpiFormData.dueDate) return;

  await nextTick();
};

watch(kpiFormData, updateChartRange);
watch(kpi, resetFormData, { immediate: true });

const handleUpdateKPI = async () => {
  v.value.$touch();

  if (v.value.$error) return;

  try {
    await updateKPI({ updateEntityKPIInput: { _id: kpi.value?._id, ...kpiFormData } });

    notify({ type: 'success', text: t('KPI has been updated.') });
  } catch {
    notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
  }
};

const isFormTouched = ref(false);
watch(kpiFormData, () => { isFormTouched.value = true; });
</script>

<template>
  <div
    v-if="goal && target && kpi"
    class="grid grid-cols-10 px-6 lg:mt-24"
  >
    <div class="col-span-10 mb-4">
      <router-link :to="{ name: 'goalsOverview' }">
        <AtButton variant="link">
          <ArrowNarrowLeftIcon class="h-4 w-4" />
          Back
        </AtButton>
      </router-link>
    </div>
    <div class="col-span-7">
      <AtHeading type="h1">
        {{ t(kpi.dataPointType.friendlyName) }}
      </AtHeading>

      <AtHeading type="h4">
        {{ t(kpi.dataPointType.question) }}
      </AtHeading>

      <AtBadge :type="kpi.status">
        {{ t(kpi.status) }}
      </AtBadge>

      <OgKPIForm
        v-model="kpiFormData"
        :dpt="kpi.dataPointType"
        :loading="loading"
      />

      <OgComments
        class="mt-6"
        :kpi="kpi"
        @created="refetch()"
      />

      <div class="mt-10">
        <AtButton
          variant="text"
          :disabled="loading || loadingMutation || !isFormTouched"
          @click="resetFormData"
        >
          {{ t('Cancel') }}
        </AtButton>

        <AtButton
          class="ml-4"
          :disabled="v.$error || loading"
          :loading="loading || loadingMutation"
          @click="handleUpdateKPI"
        >
          {{ t('Save changes') }}
        </AtButton>
      </div>
    </div>
    <div class="ml-5 col-span-3 border-l border-l-gray-200 pl-10">
      <AtHeading type="h3">
        {{ t('About this KPI') }}
      </AtHeading>

      <div
        v-if="targets.length > 0"
        class="mt-12"
      >
        <AtHeading
          type="h5"
          class="mb-5"
        >
          {{ t('Linked targets') }}
        </AtHeading>

        <ul>
          <li
            v-for="targetForList in targets"
            :key="targetForList._id"
            class="my-1"
          >
            <template v-if="targetForList.number">
              {{ t('Target {number}', { number: targetForList.number }) }}
            </template>
            <template v-else>
              {{ targetForList.name }}
            </template>
          </li>
        </ul>
      </div>

      <div
        v-if="goals.length > 0"
        class="mb-5 mt-12"
      >
        <AtHeading
          type="h5"
          class="mb-5"
        >
          {{ t('Linked goals') }}
        </AtHeading>

        <div class="flex flex-wrap gap-5">
          <ESGGoal
            v-for="linkedGoal in goals"
            :key="linkedGoal._id"
            :size="16"
            :goal="linkedGoal"
          />
        </div>
      </div>
    </div>
  </div>
</template>
