<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';
import { computed, provide, readonly, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { CheckCircleIcon } from '@heroicons/vue/outline';
import { DataPointRequestStatusEnum, type PgExternalDataEntryQuery } from '@/__generated__/types';
import { i18n } from '@/lib/i18n';
import AtProgress from '@/components/atoms/AtProgress.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import logo from '@/assets/logo.svg';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import PG_EXTERNAL_DATA_ENTRY_QUESTIONNAIRE from './PgExternalDataEntry.query';
import { switchTranslation } from './utils';
import OgExternalQuestion from './OgExternalQuestion.vue';

const delegationEmailAddress = window.localStorage.getItem('delegationEmailAddress');
const route = useRoute();
const { t, locale } = useI18n();

const { result, loading } = useQuery<PgExternalDataEntryQuery>(PG_EXTERNAL_DATA_ENTRY_QUESTIONNAIRE, {
  emailAddress: delegationEmailAddress ?? '',
  shareToken: route.params.dataEntryShareToken.toString(),
}, {
  fetchPolicy: 'network-only',
});

const currentExternalUserId = computed(() => result.value?.getCurrentExternalUserId);
const dataPointRequests = computed(() => result.value?.getDelegatedDataPointRequests ?? []);
const dataPointRequestsFiltered = computed(
  () => dataPointRequests.value
    .filter((dpr) => {
      const isOngoing = dpr.status !== DataPointRequestStatusEnum.ACCEPTED && dpr.status !== DataPointRequestStatusEnum.CLOSED;
      const isParent = !dpr.parent;
      const isChildAssignedWithParent = !!dataPointRequests.value.find((parent) => parent._id === dpr.parent?._id);
      return isOngoing && (isParent || !isChildAssignedWithParent);
    })
    .sort((a, b) => a.dataPointType.order > b.dataPointType.order ? -1 : 1)
    ?? []);
const dataPointRequestsFilteredChilds = computed(
  () => dataPointRequests.value
    .filter((dpr) => {
      const isOngoing = dpr.status !== DataPointRequestStatusEnum.ACCEPTED && dpr.status !== DataPointRequestStatusEnum.CLOSED;
      const isChildAssignedWithParent = !!dataPointRequests.value.find((parent) => parent._id === dpr.parent?._id);
      return isOngoing && isChildAssignedWithParent;
    })
    .sort((a, b) => a.dataPointType.order > b.dataPointType.order ? -1 : 1)
    ?? []);
const dataPointTypeOverrides = computed(
  () => dataPointRequests.value.filter((dpr) => dpr.override).map((_dpr) => _dpr.override) ?? [],
);

provide('dataPointTypeOverrides', readonly(dataPointTypeOverrides));

const dataPointRequestsAnswered = computed(
  () => [...dataPointRequestsFiltered.value.filter((dpr) => dpr.status === DataPointRequestStatusEnum.PENDING),
    ...dataPointRequestsFilteredChilds.value.filter((dpr) => dpr.status === DataPointRequestStatusEnum.PENDING),
  ],
);

const isReportingComplete = computed(
  () => dataPointRequestsFiltered.value.every((dpr) => dpr.status === DataPointRequestStatusEnum.PENDING)
    && dataPointRequestsFilteredChilds.value.every((dpr) => dpr.status === DataPointRequestStatusEnum.PENDING),
);

const progressPercentage = computed(
  () => (
    dataPointRequestsAnswered.value.length / (dataPointRequestsFiltered.value.length + dataPointRequestsFilteredChilds.value.length)
  ) * 100,
);

const dataPointRequestsWithValueSourceNames = computed(
  () => dataPointRequests.value
    .filter((dpr) => dpr.valueSource).map((dpr) => ({
      id: dpr._id,
      fromDate: `${dpr.from}`,
      valueSourceNames: dpr.valueSource?.map((vs) => vs.name) ?? [],
    })),
);

const activeDprId = ref('');
const activeChildId = ref('');
const activeGrandChildId = ref('');

const isCompleted = ref(false);

function toggleActiveDpr(dprId: string, childId = '', grandChildId = '') {
  const prevActiveDprId = activeDprId.value;
  const prevActiveChildId = activeChildId.value;
  const prevActiveGrandChildId = activeGrandChildId.value;

  activeDprId.value = dprId;
  activeChildId.value = childId;
  activeGrandChildId.value = grandChildId;

  if (prevActiveDprId === dprId && !childId && !grandChildId) {
    activeDprId.value = '';
  }
  if (prevActiveChildId === childId && !grandChildId) {
    activeChildId.value = '';
  }
  if (prevActiveGrandChildId === grandChildId) {
    activeGrandChildId.value = '';
  }
}

</script>

<template>
  <div class="flex flex-1 flex-col min-h-full justify-between">
    <div class="">
      <div class="pt-16 mb-5 flex justify-between">
        <router-link :to="{ name: 'loginStart' }">
          <img
            class="h-12 w-auto"
            :src="logo"
            alt="Codio Impact GmbH"
          >
        </router-link>
        <div>
          <ul class="flex justify-center">
            <li
              v-for="availableLocale in i18n.global.availableLocales"
              :key="availableLocale"
              class="ml-2 h-full cursor-pointer border-l border-gray-200 pl-2 first:border-0"
              :class="{ 'text-primary': locale === availableLocale }"
              @click="switchTranslation(availableLocale)"
            >
              {{ availableLocale.toUpperCase() }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="isReportingComplete && !loading" class="mb-5">
        <div class="justify-center flex">
          <CheckCircleIcon class="w-16 text-primary" />
        </div>
        <h3 class="text-gray-900 text-xl font-normal">
          {{ t('Thank you!') }}
        </h3>
        <p class="text-sm mb-5 break-words">
          {{ t('You completed all questions and there are no more questions left. Thank you for helping us build a sustainability report for your organisation.') }}
        </p>
      </div>
      <div v-else>
        <AtHeading type="h1" class="mb-2 font-semibold">
          {{ t("Fill out a sustainability questionnaire") }}
        </AtHeading>

        <p class="text-sm mb-5 break-words">
          {{ t("You've been invited to fill out a questionnaire and share information that helps to complete the sustainability report of your organisation.") }}
        </p>
      </div>
      <div v-if="!loading" class="mb-6">
        <AtProgress :percent="typeof progressPercentage !== 'number' ? 0 : progressPercentage" size="md" contrast="high" showPercentNumber />
      </div>
      <AtButton
        v-if="isCompleted"
        variant="text"
        @click.stop.prevent="isCompleted = false"
      >
        {{ t('Back') }}
      </AtButton>
    </div>
    <div v-if="!isCompleted" class="h-full overflow-scroll mb-5">
      <OgExternalQuestion
        v-for="dpr, index in dataPointRequestsFiltered"
        :key="dpr._id"
        class="border border-gray-200 bg-white shadow-md my-3 p-4 rounded-md"
        :class="{
          'border-primary': activeDprId === dpr._id,
        }"
        :isOpen="activeDprId === dpr._id"
        :dataPointRequestsWithValueSourceNames="dataPointRequestsWithValueSourceNames"
        :index="index"
        :dpr="dpr"
        :currentExternalUserId="currentExternalUserId"
        @toggle="toggleActiveDpr(dpr._id)"
      >
        <OgExternalQuestion
          v-for="child, childIndex in dataPointRequestsFilteredChilds.filter((childDpr) => childDpr.parent?._id === dpr._id)"
          :key="child._id"
          class="border border-gray-200 bg-white shadow-md my-3 p-4 rounded-md"
          :class="{
            'border-primary': activeChildId === child._id,
          }"
          :dataPointRequestsWithValueSourceNames="dataPointRequestsWithValueSourceNames"
          :isOpen="activeChildId === child._id"
          :index="childIndex"
          :dpr="child"
          :currentExternalUserId="currentExternalUserId"
          @toggle="toggleActiveDpr(dpr._id, child._id)"
        >
          <OgExternalQuestion
            v-for="grandChild, grandChildIndex in dataPointRequestsFilteredChilds.filter(
              (grandChildDpr) => grandChildDpr.parent?._id === child._id)"
            :key="grandChild._id"
            class="border border-gray-200 bg-white shadow-md my-3 p-4 rounded-md"
            :class="{
              'border-primary': activeGrandChildId === grandChild._id,
            }"
            :dataPointRequestsWithValueSourceNames="dataPointRequestsWithValueSourceNames"
            :index="grandChildIndex"
            :isOpen="activeGrandChildId === grandChild._id"
            :dpr="grandChild"
            :topParent="dpr"
            :currentExternalUserId="currentExternalUserId"
            @toggle="toggleActiveDpr(dpr._id, child._id, grandChild._id)"
          />
        </OgExternalQuestion>
      </OgExternalQuestion>
    </div>
    <div class="">
      <h3 class="text-xs font-semibold tracking-tight text-gray-900">
        {{ t('About Codio Impact') }}
      </h3>
      <p class="text-xs">
        {{ t('We automate the way you manage and report your Environmental, Social, Governance (ESG) data, while remaining fully compliant with the newest regulation and leading standards.') }}
      </p>
    </div>
  </div>
</template>
