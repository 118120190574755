<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import OgSimpleTable from '@/components/organisms/OgSimpleTable/OgSimpleTable.vue';
import { type PgTaxonomyAssessmentQuery, TaxonomyAssessmentStatusEnum } from '@/__generated__/types';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';
import useUpdateTaxonomyAssessmentBusinessActivity from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentBusinessActivity.mutation';

const { t } = useI18n();

type TProps = {
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment']
}

const router = useRouter();
const props = defineProps<TProps>();
const { formatNumber } = useFormatNumber();

const formData = reactive({
  totalTurnover: -1,
  activityTurnovers: {} as Record<string, number>,
});

watch(() => props.assessment, () => {
  if (Object.keys(formData.activityTurnovers).length === 0) {
    formData.activityTurnovers = props.assessment.businessActivities
      .reduce((acc, curr) => ({ ...acc, [curr._id]: Math.max(0, curr.activityTurnover) }), {});
  }

  if (formData.totalTurnover === -1) {
    formData.totalTurnover = Math.max(0, props.assessment.turnover);
  }
}, { immediate: true });

const { mutate: updateTaxonomyAssessment } = useUpdateTaxonomyAssessment();
const { mutate: updateTaxonomyAssessmentBusinessActivity } = useUpdateTaxonomyAssessmentBusinessActivity();

const taxonomyActivitiesTurnoverSum = computed(() => Object.values(formData.activityTurnovers).reduce((acc, curr) => acc + curr, 0));
const taxonomyActivitiesTurnoverPercent = computed(() => (taxonomyActivitiesTurnoverSum.value / formData.totalTurnover) * 100);
const activitiesNotIncludedSum = computed(() => formData.totalTurnover - taxonomyActivitiesTurnoverSum.value);
const activitiesNotIncludedPercent = computed(() => 100 - taxonomyActivitiesTurnoverPercent.value);

const fields = ['Activity', 'Turnover', 'Turnover Proportion'];
const items = computed(() => [...props.assessment.businessActivities.map((activity) => ({
  '_id': activity._id, 'Activity': `${activity.activity.number}: ${activity.activity.name}`, 'Turnover': activity.activityTurnover, 'Turnover Proportion': ((formData.activityTurnovers[activity._id] ?? 0) / Math.max(1, formData.totalTurnover)) * 100,
})),
{
  'Activity': t('Activities eligible under the EU Taxonomy'),
  'Turnover': taxonomyActivitiesTurnoverSum.value,
  'Turnover Proportion': taxonomyActivitiesTurnoverPercent.value,
},
{
  'Activity': t('Activities not eligible under the EU Taxonomy'),
  'Turnover': activitiesNotIncludedSum.value,
  'Turnover Proportion': activitiesNotIncludedPercent.value,
},
{
  'Activity': t('TOTAL'),
  'Turnover': (taxonomyActivitiesTurnoverSum.value + activitiesNotIncludedSum.value),
  'Turnover Proportion': (activitiesNotIncludedPercent.value + taxonomyActivitiesTurnoverPercent.value),
},
]);

const isLoading = ref(false);

const next = async () => {
  isLoading.value = true;

  await updateTaxonomyAssessment({
    assessmentId: props.assessment._id,
    input: {
      turnover: formData.totalTurnover >= 0 ? formData.totalTurnover : -1,
      status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITIES_CAPEX,
    },
  }, { update: (store) => { store.evict({ fieldName: 'getTaxonomyProjects' }); store.evict({ fieldName: 'getTaxonomyAssessment' }); } });

  for await (const [key, value] of Object.entries(formData.activityTurnovers)) {
    await updateTaxonomyAssessmentBusinessActivity({
      assessmentId: props.assessment._id,
      businessActivityId: key,
      input: {
        activityTurnover: value >= 0 ? value : -1,
      },
    }, { update: (store) => { store.evict({ fieldName: 'getTaxonomyProjects' }); store.evict({ fieldName: 'getTaxonomyAssessment' }); } });
  }

  router.push({ name: 'taxonomyAssessmentBusinessActivitiesCapex' });

  isLoading.value = false;
};

const prev = () => {
  router.push({ name: 'taxonomyAssessmentBusinessActivitiesSectorActivities' });
};

</script>

<template>
  <div>
    <AtInput v-model.number="formData.totalTurnover" type="number" :label="t('What is the total amount of company’s turnover?')" :placeholder="t('EUR')" unit="EUR" />

    <p class="my-4">
      {{ t('State your turnover from each of the selected activities') }}
    </p>

    <OgSimpleTable
      :fields="fields"
      :items="items"
    >
      <template #item-activity="item">
        {{ t(item.Activity) }}
      </template>
      <template #item-turnover="item">
        <AtInput v-if="item._id" v-model.number="formData.activityTurnovers[item._id]" unit="EUR" type="number" />
        <template v-else>
          {{ formatNumber(item.Turnover) }} EUR
        </template>
      </template>
      <template #item-turnoverProportion="item">
        <template v-if="item['Turnover Proportion'] >= 0">
          {{ formatNumber(item['Turnover Proportion']) }}%
        </template>
      </template>
    </OgSimpleTable>

    <p class="font-semibold">
      {{ t('Your total Taxonomy-eligible turnover is {number} Euro.', { number: formatNumber(taxonomyActivitiesTurnoverSum) }) }}
    </p>
  </div>

  <div class="flex justify-between">
    <AtButton class="my-4 float-right" :disabled="isLoading" variant="outline" @click="prev">
      {{ t('Back') }}
    </AtButton>

    <AtButton
      class="my-4 float-right"
      :loading="isLoading"
      :disabled="taxonomyActivitiesTurnoverPercent > 100 || formData.totalTurnover === null"
      @click="next"
    >
      {{ t('Continue') }}
    </AtButton>
  </div>
</template>
