<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApolloError } from '@apollo/client';
import { notify } from '@kyvg/vue3-notification';
import chunk from 'lodash/chunk';
import orderBy from 'lodash/orderBy';
import { DotsHorizontalIcon } from '@heroicons/vue/outline';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import AtProgress from '@/components/atoms/AtProgress.vue';
import OgSimpleTable from '@/components/organisms/OgSimpleTable/OgSimpleTable.vue';
import AtMenuItem from '@/components/molecules/MlMenu/AtMenuItem.vue';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import useRemindUsersAdminMutation from '@/api/mutations/Entity/remindUsersAdmin.mutation';
import { RemindUsersAdminType, type TmDashboardQuery } from '@/__generated__/types';

const props = defineProps<{
  numberOfWorkspaces: number,
  reportingProgress: NonNullable<TmDashboardQuery['getReportingProgress']>
}>();
const { t } = useI18n();

type TProgress = {
  id: string;
  name: string,
  pending: number,
  accepted: number
  overDue: number
  total: number,
  open: number,
  locations: Omit<TProgress, 'locations'>[],
}

const { mutate } = useRemindUsersAdminMutation();
const { confirmViaDialog } = useConfirmViaDialog();

const headers = computed(() => {
  const common = [
    t('Workspace'),
    t('Answered'),
    t('Approved'),
    t('Overdue'),
  ];

  return {
    best: common,
    worst: [...common, ''],
  };
});

const workspaceProgress = computed(() => props.reportingProgress.reduce((acc, curr) => {
  const entityIdString = curr.entity._id.toString();

  acc[entityIdString] ??= {
    id: entityIdString,
    name: curr.entity.name ?? '',
    pending: 0,
    accepted: 0,
    overDue: 0,
    total: 0,
    open: 0,
    locations: [],
  };

  acc[entityIdString].pending += curr.pending;
  acc[entityIdString].accepted += curr.accepted;
  acc[entityIdString].overDue += curr.overDue;
  acc[entityIdString].total += curr.total;
  acc[entityIdString].open += curr.open;
  acc[entityIdString].locations.push({ ...curr, id: curr.location._id, name: curr.location.name });

  return acc;
}, {} as Record<string, TProgress>));

const groups = ['best', 'worst'] as const;
const items = computed(() => {
  const sortedProgress = orderBy(
    Object.values(workspaceProgress.value),
    [(item) => (item.accepted / item.total) * 100],
    ['desc'],
  );
  const chunks = chunk(sortedProgress, Math.ceil(sortedProgress.length / 2));

  return {
    best: chunks[0],
    worst: chunks[1],
  };
});

const tdClass = computed(() => 'px-6 py-1 text-sm');

async function handleRemindByEmailClick(type: RemindUsersAdminType, progressItem: TProgress) {
  const isConfirmed = await confirmViaDialog({
    title: t('Remind by email?'),
    text: t('Send an email to the team members who have open tasks within {name} to remind them to enter data. This is helpful when progress is slow and your reporting deadline is nearing.', {
      name: progressItem.name,
    }),
    confirmLabel: t('Send reminder'),
    cancelLabel: t('Cancel'),
    confirmButtonVariant: 'default',
  });

  if (!isConfirmed) {
    return;
  }

  try {
    return mutate({
      type,
      _id: progressItem.id,
    });
  } catch (error) {
    if (error instanceof ApolloError) {
      notify({
        type: 'error',
        text: t(error.message),
        duration: 10000,
      });
    }
  }
}
</script>

<template>
  <div class="px-6 grid grid-cols-1 2xl:grid-cols-2 gap-10">
    <div v-for="group in groups" :key="group">
      <h2 class="font-medium">
        <template v-if="group === 'best'">
          {{ t('Best performing workspaces') }}
        </template>
        <template v-else-if="group === 'worst'">
          {{ t('Off-track workspaces') }}
        </template>
      </h2>

      <div class="mt-2">
        <OgSimpleTable
          :fields="headers[group]"
          :items="items[group]"
          isFixedLayout
          :customEmptyStateText="props.numberOfWorkspaces === 1 ? t('All of your workspaces are on track') : undefined"
        >
          <template #expand="{ locations }">
            <tr v-for="location in locations" :key="location._id">
              <td />
              <td class="pl-10 pr-6 text-sm">
                {{ location.name }}
              </td>
              <td :class="tdClass">
                <AtProgress
                  percentDecimals
                  showProgressionColors
                  :percent="Number(((location.accepted + location.pending) / location.total * 100))"
                  showPercentNumber
                />
              </td>
              <td :class="tdClass">
                <AtProgress
                  percentDecimals
                  showProgressionColors
                  :percent="Number((location.accepted / location.total * 100))"
                  showPercentNumber
                />
              </td>
              <td :class="tdClass">
                {{ location.overDue }} / {{ location.open }}
              </td>
              <td v-if="group === 'worst'" :class="tdClass">
                <MlMenu>
                  <button
                    type="button"
                    class="group flex items-center"
                  >
                    <DotsHorizontalIcon class="h-5 w-5 group-disabled:opacity-50" />
                  </button>
                  <template #menuItems>
                    <AtMenuItem
                      class="justify-center"
                      @click="handleRemindByEmailClick(RemindUsersAdminType.PROJECT, location)"
                    >
                      {{ t('Remind by email') }}
                    </AtMenuItem>
                  </template>
                </MlMenu>
              </td>
            </tr>
          </template>
          <template #item-name="item">
            {{ item.name }}
          </template>
          <template #item-answeredProgress="item">
            <AtProgress
              percentDecimals
              showProgressionColors
              :percent="Number(((item.accepted + item.pending) / item.total * 100))"
              showPercentNumber
            />
          </template>
          <template #item-acceptedProgress="item">
            <AtProgress
              percentDecimals
              showProgressionColors
              :percent="Number((item.accepted / item.total * 100))"
              showPercentNumber
            />
          </template>
          <template #item-overDueRatio="item">
            {{ item.overDue }} / {{ item.open }}
          </template>
          <template v-if="group === 'worst'" #item-foo="item">
            <MlMenu>
              <button
                type="button"
                class="group flex items-center"
              >
                <DotsHorizontalIcon class="h-5 w-5 group-disabled:opacity-50" />
              </button>
              <template #menuItems>
                <AtMenuItem
                  class="justify-center"
                  @click="handleRemindByEmailClick(RemindUsersAdminType.WORKSPACE, item as TProgress)"
                >
                  {{ t('Remind by email') }}
                </AtMenuItem>
              </template>
            </MlMenu>
          </template>
        </OgSimpleTable>
      </div>
    </div>
  </div>
</template>
