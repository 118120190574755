<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore, useStoreMethods } from '../../store';
import AtCard from '../../AtCard.vue';
import OgPotentialMagnitude from './OgPotentialMagnitude/OgPotentialMagnitude.vue';
import OgTimeHorizons from './OgTimeHorizons/OgTimeHorizons.vue';

const { t } = useI18n();
const store = useStore();
const { addInitialPotentialMagnitude, addInitialTimeHorizons } = useStoreMethods();

const showPotentialMagnitudeStart = ref(true);
const showTimeHorizonsStart = ref(true);

const cancelPotentialMagnitudeWatch = watchEffect(() => {
  const isAnythingSet = ([
    'criticallyRelevantFinancialEffect',
    'significantFinancialEffect',
    'relevantFinancialEffect',
    'lowFinancialEffect',
    'minorFinancialEffect',
    'almostNoFinancialEffect',
  ] as const)
    .some((key) => {
      if (store.value.potentialMagnitude[`${key}Value`] !== null && store.value.potentialMagnitude[`${key}Value`] !== undefined) {
        return true;
      }

      if (store.value.potentialMagnitude[`${key}Comment`]) {
        return true;
      }

      return !!store.value.potentialMagnitude[`${key}Document`];
    });
  if (isAnythingSet) {
    showPotentialMagnitudeStart.value = false;
    cancelPotentialMagnitudeWatch();
  }
}, { flush: 'post' });

const cancelTimeHorizonsWatch = watchEffect(() => {
  const isAnythingSet = ([
    'shortTerm',
    'mediumTerm',
    'longTerm',
  ] as const)
    .some((key) => {
      if (store.value.timeHorizons[`${key}Value`] !== null && store.value.timeHorizons[`${key}Value`] !== undefined) {
        return true;
      }

      if (store.value.timeHorizons[`${key}Comment`]) {
        return true;
      }

      return !!store.value.timeHorizons[`${key}Document`];
    });
  if (isAnythingSet) {
    showTimeHorizonsStart.value = false;
    cancelTimeHorizonsWatch();
  }
}, { flush: 'post' });

function handlePotentialMagnitudeStart() {
  addInitialPotentialMagnitude();
  showPotentialMagnitudeStart.value = false;
}

function handleTimeHorizonsStart() {
  addInitialTimeHorizons();
  showTimeHorizonsStart.value = false;
}
</script>

<template>
  <p v-if="showPotentialMagnitudeStart && showTimeHorizonsStart" class="mb-5">
    {{ t('Below are the scales and core data that you will need to define before starting your double materiality assessment.') }}
  </p>
  <AtCard
    v-if="showPotentialMagnitudeStart"
    class="mb-5"
    :title="t('Definition of potential magnitude')"
    :action="t('Start')"
    @click="handlePotentialMagnitudeStart"
  >
    <p>
      {{ t('This is the place to define different financial thresholds for your financial materiality. This means: assigning monetary value to the scales of the potential magnitude of financial effects in the short-, medium-, and long-term. The risks and opportunities that you’ll define during the DMA will be ranked in accordance with these values.') }}
    </p>
  </AtCard>
  <OgPotentialMagnitude v-else />

  <AtCard
    v-if="showTimeHorizonsStart"
    :title="t('Time horizons')"
    :action="t('Start')"
    @click="handleTimeHorizonsStart"
  >
    <p>
      {{ t('This is the place to define the time horizons that will be considered in the double materiality assessment. ESRS 1 defines standardized terms for the time horizons, only the short-term period is company-specific. If the medium-or long-term time horizons defined by the ESRS results in non-relevant information due to industry-specific characteristics, the company may adopt a different definition for these time horizons. The time horizons are defined as follows:') }}
    </p>
    <ol class="list-decimal pl-5">
      <li>
        {{ t('Short-term period (same as reporting period of the financial statements);') }}
      </li>
      <li>
        {{ t('Medium-term period (from the end of the short-term period up to 5 years); and') }}
      </li>
      <li>
        {{ t('Long-term period (more than 5 years).') }}
      </li>
    </ol>
  </AtCard>
  <OgTimeHorizons v-else />
</template>
