<script setup lang="ts">
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { DoubleMaterialityMandatoryReason, type OgRecommendedEsrsQuery } from '@/__generated__/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import { CheckboxSize } from '@/components/atoms/AtCheckbox/types';
import OgTable from '../../OgTable.vue';
import { useStore } from '../../store';
import OG_RECOMMENDED_ESRS_QUERY from './OgRecommendedESRS.query';

type RowItem = OgRecommendedEsrsQuery['getDataPointCategoriesForDoubleMateriality'][number];

const { t } = useI18n();
const store = useStore();

const { result } = useQuery<OgRecommendedEsrsQuery>(OG_RECOMMENDED_ESRS_QUERY);

const headers = computed(() => [
  { text: t('Standard'), value: 'standard', columnClasses: 'w-[250px]' },
  { text: t('Disclosure requirements'), value: 'disclosureRequirement' },
  { text: t('Reason for reporting'), value: 'reason', columnClasses: 'w-[350px]' },
]);

const items = computed<RowItem[]>(() => result.value?.getDataPointCategoriesForDoubleMateriality ?? []);

function isCheckboxDisabled(categoryId: string, subcategory: RowItem['subcategories'][number]) {
  switch (subcategory.doubleMaterialityMandatoryReason) {
    case DoubleMaterialityMandatoryReason.Regardless:
    case DoubleMaterialityMandatoryReason.TopicIsMaterial:
    case DoubleMaterialityMandatoryReason.DueToFinancialMateriality:
      return true;
    case DoubleMaterialityMandatoryReason.DueToSustainabilityMatter:
      return !store.value.recommendedESRS[`${categoryId}_${subcategory._id}`]!.materiality;
    default:
      return false;
  }
}

function handleCheckboxToggle(categoryId: string, subcategoryId: string) {
  const key = `${categoryId}_${subcategoryId}`;
  store.value.recommendedESRS[key]!.value = !store.value.recommendedESRS[key]!.value;
}
</script>

<template>
  <OgTable
    :headers="headers"
    :items="items"
    :isRowRemovable="false"
  >
    <template #item-standard="row: RowItem">
      <p>
        {{ t(row.name) }}
      </p>
    </template>
    <template #item-disclosureRequirement="row: RowItem">
      <div
        v-for="subcategory in row.subcategories"
        :key="subcategory._id"
      >
        <AtCheckbox
          squared
          :checked="store.recommendedESRS[`${row._id}_${subcategory._id}`]!.value"
          :size="CheckboxSize.XS"
          :disabled="isCheckboxDisabled(row._id, subcategory)"
          @toggleCheckbox="handleCheckboxToggle(row._id, subcategory._id)"
        >
          <template #label>
            <p class="text-sm truncate">
              {{ t(subcategory.name) }}
            </p>
          </template>
        </AtCheckbox>
      </div>
    </template>
    <template #item-reason="row: RowItem">
      <p
        v-for="subcategory in row.subcategories"
        :key="subcategory._id"
        class="truncate py-2 text-sm"
      >
        <template v-if="subcategory.doubleMaterialityMandatoryReason === DoubleMaterialityMandatoryReason.Regardless">
          {{ t('Mandatory regardless of materiality') }}
        </template>
        <template v-else-if="subcategory.doubleMaterialityMandatoryReason === DoubleMaterialityMandatoryReason.TopicIsMaterial">
          {{ t('Mandatory because topic is material') }}
        </template>
        <template v-else-if="subcategory.doubleMaterialityMandatoryReason === DoubleMaterialityMandatoryReason.DueToSustainabilityMatter">
          {{ t('Mandatory due to relevant sustainability matter') }}
        </template>
        <template v-else-if="subcategory.doubleMaterialityMandatoryReason === DoubleMaterialityMandatoryReason.DueToFinancialMateriality">
          {{ t('Mandatory due to financial materiality') }}
        </template>
      </p>
    </template>
  </OgTable>

  <div class="flex justify-end">
    <AtButton variant="destructive" :loading="false" :disabled="true">
      {{ t('Complete assessment and go to data entry') }}
    </AtButton>
  </div>
</template>
