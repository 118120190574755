<script setup lang="ts">
import { type Component, type HTMLAttributes } from 'vue';
import AtLoader from '@/components/atoms/AtLoader/AtLoader.vue';
import { Button, type ButtonVariants } from '@/components/ui/button';

const props = withDefaults(defineProps<{
  variant?: ButtonVariants['variant']
  size?: ButtonVariants['size']
  class?: HTMLAttributes['class']
  loading?: boolean;
  disabled?: boolean;
  icon?: Component,
  type?: HTMLButtonElement['type'];
}>(), {
  variant: 'default',
  size: 'default',
  color: 'primary',
  type: 'submit',
  class: undefined,
  icon: undefined,
});

</script>

<template>
  <Button
    :variant="props.variant"
    :size="props.size"
    :class="props.class"
    :disabled="props.loading || props.disabled"
    :type="props.type"
  >
    <component
      :is="props.icon"
      v-if="!props.loading && props.icon"
      class="w-4 h-4 -translate-x-1 mr-1"
    />
    <AtLoader
      v-else-if="props.loading"
      size="xs"
      class="-translate-x-1 mr-1"
    />
    <slot />
  </Button>
</template>
