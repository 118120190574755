<script setup lang="ts">
import { ref } from 'vue';
import { PencilIcon, RefreshIcon, TrashIcon } from '@heroicons/vue/outline';
import { useI18n } from 'vue-i18n';
import AtProgress from '@/components/atoms/AtProgress.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import type { TKpiItem } from '../types';
import OgUpdateKpiModal from './OgUpdateKpiModal.vue';
import PG_GOALS_OVERVIEW_QUERY from './PgGoalsOverview.query';
import { useDeleteKPIs } from './useDeleteKPIs';

const props = defineProps<{
  kpi: TKpiItem,
  goalId: string,
  targetId: string,
}>();

const isUpdateKpiModalRevealed = ref(false);

const { deleteKPIs } = useDeleteKPIs({ refetchQueries: [{ query: PG_GOALS_OVERVIEW_QUERY }] });

const { t } = useI18n();
</script>

<template>
  <div class="grid grid-cols-[1fr_3fr_1fr_1fr_1fr_0.7fr] gap-2 items-center justify-center border-b px-4 py-6">
    <span class="ml-11">{{ t('KPI') }}</span>
    <span>{{ t(props.kpi.title) }}</span>
    <span>{{ props.kpi.assignee }}</span>
    <span>{{ props.kpi.deadline }}</span>
    <AtProgress
      class="w-full max-w-[6rem]"
      :percent="props.kpi.statusPercentage"
      :status="props.kpi.statusPercentage >= 90 ? 'success' : 'warning'"
      showPercentNumber
    />
    <div class="ml-auto flex gap-1">
      <RouterLink :to="{ name: 'goalsKpi', params: { goal: props.goalId, target: props.targetId, kpi: props.kpi.kpi._id } }">
        <AtIconButton
          class="w-fit text-gray-400"
          :icon="PencilIcon"
          :title="t('Edit')"
        />
      </RouterLink>
      <AtIconButton
        class="w-fit text-gray-400"
        :icon="RefreshIcon"
        :title="t('Update')"
        @click.stop.prevent="isUpdateKpiModalRevealed = true"
      />
      <AtIconButton
        class="w-fit text-gray-400"
        :icon="TrashIcon"
        :title="t('Delete')"
        @click.stop="deleteKPIs"
      />
    </div>
  </div>
  <OgUpdateKpiModal
    v-model:isRevealed="isUpdateKpiModalRevealed"
    :kpi="props.kpi"
    :goalId="props.goalId"
    :targetId="props.targetId"
  />
</template>
