<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import type { PgEvidenceQuery, RepositoryFile } from '@/__generated__/types';
import useGenerateFileDownloadTokenMutation from '@/api/mutations/RepositoryFile/generateFileDownloadToken.mutation';
import fileImg from '@/assets/file.svg';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import OgS3FilePicker from '@/components/molecules/MlFilePicker/OgS3FilePicker.vue';
import PG_EVIDENCE_QUERY from './PgEvidence.query';

const router = useRouter();

const adminMode = inject('adminMode', computed(() => false));

const { result, loading: queryLoading, refetch } = useQuery<PgEvidenceQuery>(PG_EVIDENCE_QUERY, () => ({
  adminMode: adminMode.value,
}), {
  fetchPolicy: 'network-only',
});

const { t } = useI18n();

const { mutate: generateFileDownloadURLMutation } = useGenerateFileDownloadTokenMutation();

const downloadFile = async (file: Pick<RepositoryFile, '_id'>) => {
  const mutationResult = await generateFileDownloadURLMutation({
    repositoryFileId: file._id,
  });

  window.open(mutationResult?.data?.generateFileDownloadToken);
};

const repositoryFiles = computed(() => (adminMode.value
  ? result.value?.getEntityRepositoryFilesAdmin
  : result.value?.getEntityRepositoryFiles)
  ?? []);

const headers = computed(() => [
  ...(adminMode.value ? [
    { text: t('Workspace'), value: 'entity', sortable: true, filterable: true },
  ] : [
  ]),
  { text: t('Download'), value: 'download' },
]);

const items = computed(() => repositoryFiles.value.map((file) => {
  return {
    ...(adminMode.value ? {
      entity: t(file.entity?.name ?? ''),
    } : {}),
    fileName: file.filename,
    onFileClick: () => {
      if (!file._id) return;
      return downloadFile(file);
    },
  };
}));

const uploadedFile = ref();

watch(uploadedFile, () => {
  refetch();
  uploadedFile.value = null;
});

</script>

<template>
  <div class="pt-5 px-6 flex flex-col">
    <div class="flex-1">
      <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
        {{ t('Evidence library') }}
      </h1>

      <OgS3FilePicker
        v-model="uploadedFile"
        :showRecentFiles="false"
        repositoryFileType="evidence"
        :multiple="true"
      />

      <template v-if="!queryLoading">
        <MlEmptyStateCard
          v-if="!items.length"
          :title="t('No files yet')"
          :description="t('Start by entering data and uploading proof documents.')"
          :buttonText="t('Enter data')"
          @buttonClick="router.push({ name: 'projects' })"
        >
          <img
            class="mb-7"
            :src="fileImg"
            alt="Graph"
          >
        </MlEmptyStateCard>

        <OgDataTable
          v-else
          :headers="headers"
          :items="items"
        >
          <template #item-download="{ fileName, onFileClick }">
            <AtButton
              variant="link"
              data-cy="dataPointFile"
              @click="onFileClick"
            >
              {{ fileName }}
            </AtButton>
          </template>
        </OgDataTable>
      </template>
    </div>
  </div>
</template>
