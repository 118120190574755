<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import { BriefcaseIcon } from '@heroicons/vue/outline';
import { useRouter } from 'vue-router';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

const { t } = useI18n();
const router = useRouter();

</script>

<template>
  <div class="m-auto">
    <div class="text-center">
      <p class="font-semibold">
        {{ t('Business activities') }}
      </p>

      <BriefcaseIcon class="w-36 h-36 text-teal-600 m-auto bg-teal-50 border-teal-50 rounded-full p-5 my-3" />

      <p class="text-sm my-2">
        {{ t('{x} of {y} categories', { x: 1, y: 5 }) }}
      </p>
      <p class="my-3">
        {{ t('Select the business activities you want to assess for alignment with the EU Taxonomy.') }}
      </p>

      <AtButton class="mt-5" @click="router.push({ name: 'taxonomyAssessmentBusinessActivitiesSectorActivities' })">
        {{ t("Let's start") }}
      </AtButton>
    </div>
  </div>
</template>
