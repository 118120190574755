import { gql } from '@apollo/client/core';

export default gql`
  query PgEvidence($adminMode: Boolean!) {
    getEntityRepositoryFiles(type: "evidence") @skip(if: $adminMode) {
      _id
      ...pgEvidenceFields
    }

    getEntityRepositoryFilesAdmin(type: "evidence") @include(if: $adminMode) {
      _id
      ...pgEvidenceFields
    }
  }

  fragment pgEvidenceFields on RepositoryFile{
    _id
    filename

    entity {
      _id
      name
    }
  }
`;
