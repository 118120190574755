import { gql } from '@apollo/client/core';

export default gql`
  query PgTaxonomyAssessmentFinalise($_id: String!) {
    getTaxonomyProject(_id: $_id) {
      _id
      name

      assessments {
        _id

        turnover
        capex
        opex
        alignedTurnover
        eligibleTurnover
        nonEligibleTurnover

        alignedCapex
        eligibleCapex
        nonEligibleCapex

        alignedOpex
        eligibleOpex
        nonEligibleOpex

        businessActivities {
          _id

          activity {
            _id
            number
            name
          }
          
          activityTurnover
          activityOpex
          activityCapex

          activityFit

          alignedTurnover
          eligibleTurnover

          alignedCapex
          eligibleCapex

          alignedOpex
          eligibleOpex

          minimumStandardsRespected
        }
      }
    }
  }
`;
