<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import type { OgCoreDataDialogQuery } from '@/__generated__/types';
import useUpdateTaxonomyCoreDataMutation from '@/api/mutations/Organization/updateTaxonomyCoreData.mutation';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';
import MlCoreDataInput from './MlCoreDataInput.vue';
import OG_CORE_DATA_DIALOG_QUERY from './OgCoreDataDialog.query';

type CoreDataForm = {
  turnover: number | null;
  turnoverFile: TPartialRepositoryFile | null;
  capEx: number | null;
  capExFile: TPartialRepositoryFile | null;
  opEx: number | null;
  opExFile: TPartialRepositoryFile | null;
};

const emit = defineEmits<{
  close: [],
}>();

const { t } = useI18n();

const isSaved = ref(false);

const { result, loading } = useQuery<OgCoreDataDialogQuery>(OG_CORE_DATA_DIALOG_QUERY);

const { mutate, loading: saving } = useUpdateTaxonomyCoreDataMutation();

const isDisabled = computed(() => loading.value);
const isSubmitting = computed(() => saving.value);

const form = reactive<CoreDataForm>({
  turnover: null,
  turnoverFile: null,
  capEx: null,
  capExFile: null,
  opEx: null,
  opExFile: null,
});

watch(() => result?.value?.getTaxonomyCoreData, (newTaxonomyCoreData) => {
  if (newTaxonomyCoreData) {
    form.turnover = newTaxonomyCoreData.turnover ?? null;
    form.turnoverFile = newTaxonomyCoreData.turnoverFile?.file ?? null;
    form.capEx = newTaxonomyCoreData.capEx ?? null;
    form.capExFile = newTaxonomyCoreData.capExFile?.file ?? null;
    form.opEx = newTaxonomyCoreData.opEx ?? null;
    form.opExFile = newTaxonomyCoreData.opExFile?.file ?? null;
  }
}, { immediate: true });

function handleClose() {
  emit('close');
}

async function handleSubmit() {
  try {
    await mutate({
      input: {
        turnover: form.turnover,
        turnoverFileId: form.turnoverFile?._id ?? null,
        capEx: form.capEx,
        capExFileId: form.capExFile?._id ?? null,
        opEx: form.opEx,
        opExFileId: form.opExFile?._id ?? null,
      },
    });
    isSaved.value = true;
  } catch {
    notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
  }
}
</script>

<template>
  <MlDialog
    isOpen
    :class="{
      '!bg-blue-50': isSaved,
    }"
    :closeOnOutside="false"
    @close="handleClose"
  >
    <template #title>
      {{ isSaved ? t('Success!') : t('Assign, inspect or amend the core values') }}
    </template>

    <template #default="{ close }">
      <form v-if="!isSaved" :key="loading.toString()" class="min-w-[600px] pt-9" @submit.prevent="handleSubmit">
        <div class="max-w-md mb-6">
          <div class="mb-4">
            <MlCoreDataInput
              v-model:value="form.turnover"
              v-model:file="form.turnoverFile"
              v-model:isLoading="isSubmitting"
              :label="t('Turnover')"
            />
          </div>
          <div class="mb-4">
            <MlCoreDataInput
              v-model:value="form.capEx"
              v-model:file="form.capExFile"
              v-model:isLoading="isSubmitting"
              :label="t('CapEx')"
            />
          </div>
          <div>
            <MlCoreDataInput
              v-model:value="form.opEx"
              v-model:file="form.opExFile"
              v-model:isLoading="isSubmitting"
              :label="t('OpEx')"
            />
          </div>
        </div>
        <div class="flex space-x-2 justify-between">
          <AtButton type="button" variant="outline" @click="close">
            {{ t('Cancel') }}
          </AtButton>
          <AtButton type="submit" :disabled="isDisabled">
            {{ t('Save') }}
          </AtButton>
        </div>
      </form>

      <div v-else class="pt-4 max-w-md">
        <p class="text-sm mb-8">
          {{ t('You have successfully saved your core data. You can always inspect and change the values on this page.') }}
        </p>
        <div class="flex justify-end">
          <AtButton type="button" variant="default" @click="close">
            {{ t('Finish and close') }}
          </AtButton>
        </div>
      </div>
    </template>
  </MlDialog>
</template>
