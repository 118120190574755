<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { QuestionMarkCircleIcon, XIcon } from '@heroicons/vue/outline';
import useOpenHelpDocumentation from '@/utils/composables/useOpenHelpDocumentation';
import useRequestSupportMutation from '@/api/mutations/requestSupport.mutation';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

const { t } = useI18n();

const {
  mutate: requestSupportMutation,
  loading: requestSupportLoading,
  onDone: onRequestSupportSuccess,
  onError: onRequestSupportError,
} = useRequestSupportMutation();

const openHelpDocumentation = useOpenHelpDocumentation();

const isSupportPopoverVisible = ref(false);

const phone = ref('');

const requestSupportSubmitted = ref(false);

async function requestSupport() {
  await requestSupportMutation({
    requestSupportInput: {
      phone: phone.value,
    },
  });
}

onRequestSupportSuccess(() => {
  requestSupportSubmitted.value = true;
});

onRequestSupportError((error) => {
  // eslint-disable-next-line no-console
  console.error('Could not request support', error);
});

defineOptions({ inheritAttrs: false });

</script>

<template>
  <div class="p-4">
    <VMenu
      placement="top-start"
      class="shrink-0 align-baseline flex"
      instantMove
      :autoHide="false"
    >
      <div class="flex gap-3 cursor-pointer hover:text-primary items-center group relative" v-bind="$attrs">
        <QuestionMarkCircleIcon class="w-6" />
        <slot />
      </div>
      <template #popper>
        <div class="cursor-pointer border border-gray-200 rounded-md text-sm font-semibold">
          <div class="p-2 border-b">
            {{ t('Help') }}
          </div>
          <div>
            <div
              class="max-h-screen w-[159px]"
              @click.stop
            >
              <nav
                class="flex h-[90%] flex-col"
                :class="{ 'overflow-y-auto': true }"
                data-cy="MlSidebarTabs-items"
              >
                <button
                  class="break-words font-normal p-2 text-left text-gray-900 hover:text-primary hover cursor-pointer"
                  type="button"
                  @click.stop="openHelpDocumentation"
                >
                  {{ t('Documentation') }}
                </button>
                <button
                  class="break-words rounded-md font-normal p-2 text-left text-gray-900 hover:text-primary hover cursor-pointer"
                  type="button"
                  @click.stop="isSupportPopoverVisible = !isSupportPopoverVisible"
                >
                  {{ t('Support') }}
                </button>
              </nav>
            </div>
            <transition name="fade">
              <div
                v-if="isSupportPopoverVisible"
                click.stop
                class="z-50 w-screen max-w-sm rounded-md bg-blue-600 p-5 text-white shadow-lg"
              >
                <AtIconButton
                  :icon="XIcon"
                  :title="t('Close')"
                  class="absolute right-3 top-3 h-6"
                  @click.stop="isSupportPopoverVisible = false"
                />

                <template v-if="!requestSupportSubmitted">
                  <p class="text-base mb-2 mr-7">
                    {{ t('Talk to Codio Impact Support') }}
                  </p>
                  <p class="mb-4 text-xs">
                    {{ t('Enter your phone number and our Support team will call you.') }}
                  </p>

                  <div class="flex gap-4 text-black">
                    <AtInput
                      v-model="phone"
                      class="text-gray-900"
                      wrapperClass="flex-1"
                      type="tel"
                      :placeholder="t('Enter your phone number')"
                    />
                    <AtButton
                      variant="default"
                      :disabled="!phone"
                      :loading="requestSupportLoading"
                      @click.stop="requestSupport"
                    >
                      {{ t('Request Call') }}
                    </AtButton>
                  </div>
                </template>

                <template v-if="requestSupportSubmitted">
                  <p class="text-base mb-2">
                    {{ t('Have your phone ready') }}
                  </p>
                  <p class="mb-2 text-xs">
                    {{ t('The Codio Impact Support team will call you in a few minutes. Thank you for your patience.') }}
                  </p>
                </template>
              </div>
            </transition>
          </div>
        </div>
      </template>
    </VMenu>
  </div>
</template>
