<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import type { ChartOptions } from 'chart.js';
import { computed } from 'vue';
import type { TUniversalChartData } from '@/components/organisms/OgChart/types';
import OgChart from '@/components/organisms/OgChart/OgChart.vue';

const { t } = useI18n();

const props = defineProps<Props>();
type Props = {
  x: number
  y: number
}

const chartData:TUniversalChartData = {
  datasets: [{
    backgroundColor: ['#0D9488', '#EFF6FF'],
    data: [((props.x * 100) / props.y), 100 - ((props.x * 100) / props.y)],
  }],
};

const chartOptions: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      font: {
        size: 20,
      },
      borderRadius: 3,
      display: false,
      color: ['#fff', '#fff'],
    },
    doughnutlabel: {
      labels: [
        { font: { size: 60 }, text: t('{x} of {y} categories', { x: props.x, y: props.y }) },
      ],
    },
  },
};

const numberStep = computed(() => {
  switch (props.x) {
    case 1: return t('first');
    case 2: return t('second');
    case 3: return t('third');
    case 4: return t('fourth');
    case 5: return t('fifth');
    default: return t('first');
  }
});
</script>

<template>
  <div class="text-center">
    <p class="font-semibold">
      <slot>
        {{ t('Great work, {numberStep} category done!', { numberStep }) }}
      </slot>
    </p>
    <OgChart
      class="bg-white"
      type="pie"
      :chartData="chartData"
      :chartOptions="chartOptions"
    />
  </div>
</template>
