<script setup lang="ts">
import { useSlots } from 'vue';
import { useI18n } from 'vue-i18n';
import XIcon from '@heroicons/vue/outline/XIcon';
import AtIconButton from '@/components/atoms/AtIconButton.vue';

export type Header = {
  text: string;
  value: string;
  columnClasses?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Item = any & { _id: string };

withDefaults(
  defineProps<{
    headers: Header[];
    items: Item[];
    isRowRemovable?: boolean;
    tableClass?: string;
  }>(),
  {
    isRowRemovable: true,
    tableClass: '',
  },
);

const emit = defineEmits<{
  removeRow: [event: number];
}>();

const slots = useSlots();
const { t } = useI18n();

function handleRemoveRowClick(index: number) {
  emit('removeRow', index);
}
</script>

<template>
  <table class="w-full m-0 border-t" :class="[{ 'mb-6': items.length > 0 }, tableClass]">
    <colgroup>
      <col
        v-for="header in headers"
        :key="header.value"
        :class="header.columnClasses"
      />
    </colgroup>
    <thead class="sticky top-0 bg-white z-[60]">
      <tr class="text-sm h-16">
        <th
          v-for="header in headers"
          :key="header.value"
          class="uppercase first:pl-8 px-1.5 py-0 text-gray-400"
        >
          <span class="flex items-center justify-center">
            {{ header.text }}
          </span>
        </th>
      </tr>
      <tr>
        <th class="h-px p-0 bg-border" :colspan="headers.length" />
      </tr>
    </thead>
    <tbody
      v-for="(item, itemIndex) in items"
      :key="item._id"
      class="group relative hover:bg-gray-50"
    >
      <tr
        class="text-sm h-16"
        :class="{
          'h-20': !slots.expand,
          'h-16': slots.expand,
        }"
      >
        <td
          v-for="(header, headerIndex) in headers"
          :key="header.value"
          class="pt-5 align-top text-left"
          :class="{
            'border-b pb-5': !slots.expand,
            'pb-1': slots.expand,
            'pl-8 pr-1.5': headerIndex === 0,
            'px-1.5': headerIndex > 0 && headerIndex < headers.length - 1,
            'pl-1.5 pr-8': headerIndex === headers.length - 1,
          }"
        >
          <slot
            v-if="slots[`item-${header.value}`]"
            :name="`item-${header.value}`"
            v-bind="item"
          />
          <template v-else>
            {{ item[header.value] ?? '' }}
          </template>
        </td>
        <td v-if="isRowRemovable" class="absolute z-10 right-1 top-1 hidden group-hover:block p-0">
          <AtIconButton
            class="text-gray-400 h-6 w-6"
            :title="t('Remove row')"
            :icon="XIcon"
            @click="handleRemoveRowClick(itemIndex)"
          />
        </td>
      </tr>
      <tr v-if="slots.expand" class="text-sm">
        <td :colspan="headers.length" class="border-b pl-8 pr-1.5 align-top">
          <slot
            name="expand"
            v-bind="item"
          />
        </td>
      </tr>
    </tbody>

    <tfoot v-if="slots.footer">
      <tr class="text-sm">
        <td :colspan="headers.length" class="pl-8 pr-1.5 py-4">
          <slot name="footer" />
        </td>
      </tr>
    </tfoot>
  </table>

  <div v-if="items.length === 0" class="text-center text-xs p-5 mb-6">
    {{ t('No Available Data') }}
  </div>
</template>
