<script setup lang="ts">
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { formatDateRangeForBE } from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import chartImg from '@/assets/chart.svg';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import OgDateRangeInput from '@/components/organisms/OgDateRangeInput.vue';
import type { DataReadyEvent } from '../PgResults.vue';
import { usePgQualitativeView } from './composables';
import OgQualitativeTable from './OgQualitativeTable/OgQualitativeTable.vue';

const emit = defineEmits<{
  dataReady: [
    event: {
      type: 'qualitative';
      data: DataReadyEvent['data'];
    },
  ];
}>();

const router = useRouter();
const { t } = useI18n();

const {
  filters,
  filtersData,
  noData,
  dataPointTypesData,
} = usePgQualitativeView();

watch(
  [
    dataPointTypesData,
    () => filters.dateRange,
    () => filters.categories,
    () => filters.workspaces,
  ],
  ([newDataPointsTypes, newDateRangeFilter, newCategories, newWorkspaces]) => {
    const { dateFrom, dateTo } = formatDateRangeForBE(newDateRangeFilter.from, newDateRangeFilter.to);

    emit('dataReady', {
      type: 'qualitative',
      data: {
        dataPointTypeIds: newDataPointsTypes.map((dataPointType) => dataPointType.type._id),
        dataPointTypeNames: newDataPointsTypes.map((dataPointType) => dataPointType.type.name),
        dateFrom,
        dateTo,
        project: '',
        categories: newCategories,
        workspaces: newWorkspaces,
      },
    });
  },
);
</script>

<template>
  <div class="grid">
    <div class="mb-6 flex gap-2.5">
      <MlSelect
        v-model="filters.workspaces"
        class="w-full"
        :title="t('Workspace')"
        :placeholder="t('All workspaces')"
        :options="filtersData.workspaceOptions.value"
        multiple
      />

      <MlSelect
        v-model="filters.categories"
        wrapperClass="w-full"
        :placeholder="t('All categories')"
        multiple
        :options="filtersData.categoriesOptions.value"
      />

      <MlSelect
        v-model="filters.subcategories"
        wrapperClass="w-full"
        :placeholder="t('All topics')"
        multiple
        :options="filtersData.subcategoriesOptions.value"
      />

      <MlSelect
        v-model="filters.standards"
        wrapperClass="w-full"
        :placeholder="t('All standards')"
        multiple
        :options="filtersData.standardsOptions.value"
      />

      <OgDateRangeInput v-model="filters.dateRange" isFull />
    </div>

    <MlEmptyStateCard
      v-if="noData"
      :title="t('No data yet')"
      :description="t('Start by entering numeric data')"
      :buttonText="t('Enter data')"
      @buttonClick="router.push({ name: 'projects' })"
    >
      <img
        class="mb-7"
        :src="chartImg"
        alt="Graph"
      >
    </MlEmptyStateCard>

    <OgQualitativeTable
      v-for="dataPointType in dataPointTypesData"
      :key="dataPointType.type._id"
      :dataPointType="dataPointType.type"
      :frameworks="dataPointType.frameworks"
      :allDataPoints="dataPointType.allDataPoints"
      :summaryDataPoints="dataPointType.summaryDataPoints"
      :projects="dataPointType.dataPointsPerProject"
    />
  </div>
</template>
