<script setup lang="ts">
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { DotsHorizontalIcon } from '@heroicons/vue/solid';
import AtBadge from '@/components/atoms/AtBadge.vue';
import AtTooltipIcon from '@/components/atoms/AtTooltipIcon.vue';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import { translateFormular } from '@/utils/helpers/translateFormular';
import { getDisplayedFrameworks } from '@/utils/helpers/getDisplayedFrameworks';
import { UserRole } from '@/__generated__/types';
import AtDataPointRequestDate from '@/components/atoms/AtDataPointRequestDate.vue';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import MlMenu, { AtMenuItem } from '@/components/molecules/MlMenu/MlMenu.vue';
import type { Crud, DataPointsByDataPointType, DataPointsByProject, LocationSummary } from './types';
import MlInsight from './MlInsight.vue';

const props = defineProps<Props>();
type Props = {
  dataPointsByDataPointType: DataPointsByDataPointType
  projects: LocationSummary[]
  isEditable: boolean
}

const emit = defineEmits<Emits>();
type Emits = {
  openModal: [event: {
    crud: Crud,
    insightId: string,
  }],
}

const { currentUser } = useCurrentUser();

const adminMode = inject('adminMode', computed(() => false));

const { t } = useI18n();

const dataPointType = computed(() => props.dataPointsByDataPointType.dataPointType);
const dataPoints = computed(() => props.dataPointsByDataPointType.dataPoints);

const isUserResponsibleForDataPointType = computed(
  () => !!currentUser.value?.assignments.subcategories.find(
    (subcategory) => !!dataPointType.value.reportingFrameworks.find((rf) => rf.subcategory === subcategory.subcategory),
  ) || !!currentUser.value?.assignments.datapointtypes.find(
    (dpt) => dpt.dataPointType._id === dataPointType.value._id,
  ),
);

const editAndDeletePermittedRoleFrom = computed(
  () => isUserResponsibleForDataPointType.value
    ? UserRole.MANAGER
    : UserRole.ADMIN,
);

const dataPointsByProjects = computed(() => props.projects
  .map<DataPointsByProject>((project) => ({
    project,
    dataPoints: dataPoints.value.filter((dp) => adminMode.value ? dp.entity?._id === project._id : dp.location?._id === project._id),
  }))
  .sort((a, b) => (a.project?.name?.toLowerCase() ?? '') > (b.project?.name?.toLowerCase() ?? '') ? 1 : -1),
);

const currentDataPointsFrom = computed(
  () => [...dataPointsByProjects.value][0].dataPoints.sort((a, b) => a.from.toLowerCase() > b.from.toLowerCase() ? -1 : 1)[0]?.from,
);
const currentDataPointsTo = computed(
  () => [...dataPointsByProjects.value][0].dataPoints.sort((a, b) => a.to.toLowerCase() > b.to.toLowerCase() ? -1 : 1)[0]?.to,
);

</script>
<template>
  <div
    class="mb-6 grid rounded-md bg-gray-50 p-4"
  >
    <div class="flex justify-between">
      <div class="flex w-4/5 items-center">
        <AtHeading
          type="h2"
          class="pb-3 pt-2 text-xl font-normal leading-5"
        >
          {{ t(dataPointType.friendlyName) }}
          <AtTooltipIcon
            v-if="dataPointType.formular"
            class="cursor-pointer"
            :triggers="['click', 'touch']"
            :delay="0"
            autoHide
            :tooltip="translateFormular(dataPointType.formular, Array.from(dataPointType.formularDependencies))"
          />
          <AtBadge
            v-for="framework in getDisplayedFrameworks(
              props.dataPointsByDataPointType.dataPoints[0].dataPointType.activeReportingFramework,
              props.dataPointsByDataPointType.dataPoints[0].dataPointType.reportingFrameworks,
            )"
            :key="framework.framework"
            class="mr-2 py-0 text-xs font-light"
            type="clear"
          >
            <span v-if="framework.framework">{{ t(framework.framework) }}</span>
            <template v-if="framework.groups?.length">
              -
            </template>
            <template v-for="(group, i) in framework.groups">
              {{ group }}
              <template v-if="i + 1 < (framework.groups?.length ?? 0)">
                ,
              </template>
            </template>
          </AtBadge>
        </AtHeading>
      </div>
      <div class="absolute right-12">
        <MlMenu v-if="!adminMode">
          <button
            class="group"
          >
            <DotsHorizontalIcon class="h-6 group-disabled:opacity-50 text-gray-400" />
          </button>
          <template #menuItems>
            <AtMenuItem
              v-if="isEditable"
              v-rolefrom="editAndDeletePermittedRoleFrom"
              @click.stop="emit('openModal', { crud: 'update', insightId: dataPointType._id })"
            >
              {{ t('Edit') }}
            </AtMenuItem>
            <AtMenuItem
              v-rolefrom="editAndDeletePermittedRoleFrom"
              @click.stop="emit('openModal', { crud: 'delete', insightId: dataPointType._id })"
            >
              {{ t('Delete') }}
            </AtMenuItem>
          </template>
        </MlMenu>
      </div>
    </div>
    <AtDataPointRequestDate
      v-if="currentDataPointsFrom"
      :from="currentDataPointsFrom"
      :to="currentDataPointsTo"
      class="mb-3 text-gray-400"
    />
    <div class="grid auto-cols-max grid-flow-col overflow-auto">
      <div
        v-for="(dataPointsByProject, i) in dataPointsByProjects"
        :key="dataPointsByProject.project?._id"
        :class="{
          'ml-9 border-l pl-9': !(i === 0),
        }"
      >
        <MlInsight
          v-if="dataPointsByProject.dataPoints.length"
          :dataPointsByProject="dataPointsByProject"
          class="w-64 border-gray-400"
        />
        <MlInsight
          v-else
          :dataPointsByProject="dataPointsByProject"
          class="w-64 border-gray-400"
        />
      </div>
    </div>
  </div>
</template>
