import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';

import type { UpdateTaxonomyProjectMutation, UpdateTaxonomyProjectMutationVariables } from '../../../__generated__/types';

const UPDATE_TAXONOMY_PROJECT_MUTATION = gql`
  mutation UpdateTaxonomyProject($updateTaxonomyProjectInput: UpdateTaxonomyProjectInput!) {
    updateTaxonomyProject(updateTaxonomyProjectInput: $updateTaxonomyProjectInput) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<UpdateTaxonomyProjectMutation, UpdateTaxonomyProjectMutationVariables>

const useUpdateTaxonomyProjectMutation = (options?: TOptions) => useMutation<
  UpdateTaxonomyProjectMutation,
  UpdateTaxonomyProjectMutationVariables
>(UPDATE_TAXONOMY_PROJECT_MUTATION, options);

export default useUpdateTaxonomyProjectMutation;
