<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { TaxonomyAssessmentStatusEnum, type PgTaxonomyAssessmentQuery } from '@/__generated__/types';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';
import OgAssessmentFinishingProgress from '../OgAssessmentFinishingProgress.vue';

const { t } = useI18n();
const router = useRouter();
const props = defineProps<Props>();
type Props = {
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment']
}

const { mutate: updateTaxonomyAssessment } = useUpdateTaxonomyAssessment();

const next = async () => {
  const isAssessmentCompleted = !props.assessment.businessActivities.some((activity) => activity.minimumStandardsRespected);

  if (isAssessmentCompleted) {
    await updateTaxonomyAssessment({
      assessmentId: props.assessment._id,
      input: {
        status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED,
      },
    }, { update: (store) => { store.evict({ fieldName: 'getTaxonomyProjects' }); store.evict({ fieldName: 'getTaxonomyAssessment' }); } });
  }
  router.push({ name: 'taxonomyAssessmentDocuments' });
};

const prev = () => {
  router.push({ name: 'taxonomyAssessmentMinimumSafeguardsChecklist' });
};
</script>

<template>
  <div class="m-auto">
    <OgAssessmentFinishingProgress :x="4" :y="5" />
  </div>

  <div class="flex justify-between w-full">
    <AtButton class="my-4 float-right" variant="outline" @click="prev">
      {{ t('Back') }}
    </AtButton>

    <AtButton class="my-4 float-right" @click="next">
      {{ t('Continue') }}
    </AtButton>
  </div>
</template>
