import { DoubleMaterialityIroImpactType } from '@/__generated__/types';

export type Materiality = boolean | null;

export function calculateMaterialityForActualNegative(
  scale: number | null,
  scope: number | null,
  irremediability: number | null,
): Materiality {
  if (typeof scale !== 'number' || typeof scope !== 'number' || typeof irremediability !== 'number') {
    return null;
  }

  return scale >= 5 || scope >= 5 || irremediability >= 5;
}

export function calculateMaterialityForPotentialNegative(
  scale: number | null,
  scope: number | null,
  irremediability: number | null,
  likelihood: number | null,
): Materiality {
  if (typeof scale !== 'number' || typeof scope !== 'number' || typeof irremediability !== 'number' || typeof likelihood
    !== 'number') {
    return null;
  }

  const max = Math.max(scale, scope, irremediability);

  return max >= 6 || max + likelihood >= 8;
}

export function calculateMaterialityForActualPositive(
  scale: number | null,
  scope: number | null,
): Materiality {
  if (typeof scale !== 'number' || typeof scope !== 'number') {
    return null;
  }

  return scale >= 5 || scope >= 5;
}

export function calculateMaterialityForPotentialPositive(
  scale: number | null,
  scope: number | null,
  likelihood: number | null,
): Materiality {
  if (typeof scale !== 'number' || typeof scope !== 'number' || typeof likelihood
    !== 'number') {
    return null;
  }

  const max = Math.max(scale, scope);

  return max >= 6 || max + likelihood >= 8;
}

export function calculateMaterialityForImpact(
  scale: number | null,
  scope: number | null,
  irremediability: number | null,
  likelihood: number | null,
  type: DoubleMaterialityIroImpactType | null,
): Materiality {
  switch (type) {
    case DoubleMaterialityIroImpactType.ACTUAL_NEGATIVE:
      return calculateMaterialityForActualNegative(scale, scope, irremediability);
    case DoubleMaterialityIroImpactType.POTENTIAL_NEGATIVE:
      return calculateMaterialityForPotentialNegative(scale, scope, irremediability, likelihood);
    case DoubleMaterialityIroImpactType.ACTUAL_POSITIVE:
      return calculateMaterialityForActualPositive(scale, scope);
    case DoubleMaterialityIroImpactType.POTENTIAL_POSITIVE:
      return calculateMaterialityForPotentialPositive(scale, scope, likelihood);
    default:
      return null;
  }
}

export function calculateMaterialityForRiskAndOpportunity(
  likelihood: number | null,
  potentialMagnitude: number | null,
): boolean | null {
  if (typeof likelihood !== 'number' || typeof potentialMagnitude !== 'number') {
    return null;
  }

  return likelihood >= 5 || potentialMagnitude >= 5;
}

export function calculateSeverityForImpact(
  scale: number | null,
  scope: number | null,
  irremediability: number | null,
  type: DoubleMaterialityIroImpactType | null,
): number | null {
  if (typeof scale !== 'number' || typeof scope !== 'number') {
    return null;
  }

  switch (type) {
    case DoubleMaterialityIroImpactType.ACTUAL_NEGATIVE:
      return typeof irremediability !== 'number' ? null : (scale + scope + irremediability) / 3;
    case DoubleMaterialityIroImpactType.POTENTIAL_NEGATIVE:
      return typeof irremediability !== 'number' ? null : (scale + scope + irremediability) / 3;
    case DoubleMaterialityIroImpactType.ACTUAL_POSITIVE:
      return (scale + scope) / 2;
    case DoubleMaterialityIroImpactType.POTENTIAL_POSITIVE:
      return (scale + scope) / 2;
    default:
      return null;
  }
}

export function calculateTotalScoreForImpact(
  severity: number | null,
  likelihood: number | null,
  type: DoubleMaterialityIroImpactType | null,
): number | null {
  switch (type) {
    case DoubleMaterialityIroImpactType.ACTUAL_NEGATIVE:
    case DoubleMaterialityIroImpactType.ACTUAL_POSITIVE:
      return severity;
    case DoubleMaterialityIroImpactType.POTENTIAL_NEGATIVE:
    case DoubleMaterialityIroImpactType.POTENTIAL_POSITIVE:
      return (typeof likelihood !== 'number' || typeof severity !== 'number') ? null : (severity + likelihood) / 2;
    default:
      return null;
  }
}

export function calculateTotalScoreForRiskAndOpportunity(
  likelihood: number | null,
  potentialMagnitude: number | null,
): number | null {
  if (typeof likelihood !== 'number' || typeof potentialMagnitude !== 'number') {
    return null;
  }

  return (likelihood + potentialMagnitude) / 2;
}
