import { gql } from '@apollo/client/core';

export default gql`
  query PgTaxonomyOverview {
    getTaxonomyProjects {
      _id
      name
      assignee {
        _id
        firstName
        lastName
      }
      
      assessments {
        _id
        
        status
        
        turnover
        alignedTurnover
        eligibleTurnover
        nonEligibleTurnover

        capex
        alignedCapex
        eligibleCapex
        nonEligibleCapex
        
        opex
        alignedOpex
        eligibleOpex
        nonEligibleOpex

        updatedAt
        
        businessActivities {
          _id
          
          activity {
            _id
            number
            name
          }
          
          activityTurnover
          activityFit
          
          alignedTurnover
          eligibleTurnover
          
          minimumStandardsRespected
        }
        
      }
    }
  }
`;
