<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { calculateMaterialityForRiskAndOpportunity } from '../../../utils';
import { useStore } from '../../../store';
import OgScore, { type RowItem } from '../OgScore.vue';

const { t } = useI18n();
const store = useStore();

const headers = computed(() => [
  { text: t('Risks and opportunities'), value: 'riskAndOpportunity' },
  { text: t('Likelihood'), value: 'likelihood', columnClasses: 'w-[150px]' },
  { text: t('Potential magnitude'), value: 'potentialMagnitude', columnClasses: 'w-[150px]' },
  { text: t('Materiality'), value: 'materiality', columnClasses: 'w-[100px]' },
]);

const items = computed<RowItem[]>(() => store
  .value
  .iroRisksAndOpportunities
  .filter((iroRiskAndOpportunity) => iroRiskAndOpportunity.riskAndOpportunity)
  .map((iroRiskAndOpportunity) => ({
    _id: iroRiskAndOpportunity._id,
    impact: null,
    riskAndOpportunity: iroRiskAndOpportunity,
    topic: store.value.topics.find((topic) => topic._id === iroRiskAndOpportunity.topicId)!,
    materiality: calculateMaterialityForRiskAndOpportunity(iroRiskAndOpportunity.likelihood, iroRiskAndOpportunity.potentialMagnitude),
  })));
</script>

<template>
  <OgScore
    :headers="headers"
    :items="items"
    :title="t('Identified risks and opportunities:')"
    infoType="warning"
    goBackRoute="doubleMaterialityIROsScorePotentialPositive"
    nextRoute="doubleMaterialityStakeholdersStakeholders"
  >
    <p class="mb-4">
      {{ t('For risks and opportunities, materiality is determined by the likelihood and potential magnitude of the risk/opportunity. Please rate these 2 criteria from 1 (being the lowest) to 6 (being the highest):') }}
    </p>
    <ul class="list-disc pl-6">
      <li>
        {{ t('Likelihood: the likelihood that the risk or opportunity actually occurs.') }}
      </li>
      <li>
        {{ t('Potential magnitude: the potential magnitude of the financial effects of the risks and opportunities in the short-, medium- and long-term.') }}
      </li>
    </ul>
  </OgScore>
</template>
