<script setup lang="ts">
import { computed, inject, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import MlSelectLevel3 from '@/components/molecules/MlSelectLevel3/MlSelectLevel3.vue';
import { formatDateRangeForBE } from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import chartImg from '@/assets/chart.svg';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import OgDateRangeInput from '@/components/organisms/OgDateRangeInput.vue';
import type { DataPoint } from '../types';
import type { DataReadyEvent, SingleStandardTableExportEvent } from '../PgResults.vue';
import { usePgStandardView } from './composables';
import OgStandardTable from './OgStandardTable/OgStandardTable.vue';

const emit = defineEmits<{
  dataReady: [
    event: {
      type: 'standard';
      data: DataReadyEvent['data'];
    },
  ];
  export: [
    event: SingleStandardTableExportEvent,
  ];
}>();

const router = useRouter();
const { t } = useI18n();

const adminMode = inject('adminMode', computed(() => false));

const {
  isLoading: isLoadingData,
  filters,
  filtersData,
  noData,
  dataPointTypesData,
} = usePgStandardView();

watch(
  [
    dataPointTypesData,
    () => filters.dateRange,
    () => filters.project,
    () => filters.categories,
    () => filters.workspaces,
  ],
  ([newDataPointsTypes, newDateRangeFilter, newProject, newCategories, newWorkspaces]) => {
    const { dateFrom, dateTo } = formatDateRangeForBE(newDateRangeFilter.from, newDateRangeFilter.to);

    emit('dataReady', {
      type: 'standard',
      data: {
        dataPointTypeIds: newDataPointsTypes.map((dataPointType) => dataPointType.type._id),
        dataPointTypeNames: newDataPointsTypes.map((dataPointType) => dataPointType.type.name),
        dateFrom,
        dateTo,
        project: newProject,
        categories: newCategories,
        workspaces: newWorkspaces,
      },
    });
  },
);

function handeExportStandardsAsCsv(dataPointType: DataPoint['dataPointType']) {
  emit(
    'export',
    {
      dataPointTypeId: dataPointType._id,
      dataPointTypeName: dataPointType.name,
    },
  );
}
</script>

<template>
  <div class="grid">
    <div class="mb-6 grid grid-cols-12 gap-2.5">
      <MlSelectLevel3
        v-if="!adminMode"
        v-model="filters.project"
        wrapperClass="col-span-2"
        size="md"
        :placeholder="t('All projects')"
        :items="filtersData.projectsOptions.value"
      />

      <MlSelect
        v-if="adminMode"
        v-model="filters.workspaces"
        wrapperClass="col-span-2"
        :title="t('Workspace')"
        :placeholder="t('All workspaces')"
        truncate
        :options="filtersData.workspaceOptions.value"
        multiple
      />

      <MlSelect
        v-model="filters.categories"
        wrapperClass="col-span-3"
        :placeholder="t('All categories')"
        multiple
        truncate
        :options="filtersData.categoriesOptions.value"
      />

      <MlSelect
        v-model="filters.subcategories"
        wrapperClass="col-span-3"
        :placeholder="t('All topics')"
        multiple
        truncate
        class="truncate max-w-p"
        :options="filtersData.subcategoriesOptions.value"
      />

      <MlSelect
        v-model="filters.standards"
        wrapperClass="col-span-2"
        :placeholder="t('All standards')"
        multiple
        :options="filtersData.standardsOptions.value"
      />
      <OgDateRangeInput v-model="filters.dateRange" class="ml-auto col-span-2 w-full" isFull />
    </div>

    <MlEmptyStateCard
      v-if="noData"
      :title="t('No data yet')"
      :description="t('Start by entering numeric data')"
      :buttonText="t('Enter data')"
      @buttonClick="router.push({ name: 'projects' })"
    >
      <img
        class="mb-7"
        :src="chartImg"
        alt="Graph"
      >
    </MlEmptyStateCard>

    <OgStandardTable
      v-for="dataPointType in dataPointTypesData"
      :key="dataPointType.type._id"
      :dataPointType="dataPointType.type"
      :frameworks="dataPointType.frameworks"
      :allDataPoints="dataPointType.allDataPoints"
      :projects="dataPointType.dataPointsPerProject"
      :shouldDisableExport="isLoadingData"
      :showTotal="!filters.project"
      @export="handeExportStandardsAsCsv(dataPointType.type)"
    />
  </div>
</template>
