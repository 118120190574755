import { gql } from '@apollo/client/core';

export default gql`
  query PgTaxonomyProjects {
    getTaxonomyProjects {
      _id
      name
      
      assignee {
        _id
        firstName
        lastName
      }
      
      assessments {
        _id
        
        status 

        updatedAt

        documents {
          _id
          file {
            _id
            filename
            downloadUrl
            filesize
          }
        }
        
        turnover
        alignedTurnover
        eligibleTurnover
        nonEligibleTurnover

        businessActivities {
          _id
          activity {
            _id
            name
          }
          taxonomyFit
          activityTurnover
          eligibleTurnover
          alignedTurnover
        }
      }
    }
  }
`;
