<script setup lang="ts">

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

</script>

<template>
  <p>
    {{ t('To ensure your data point is a clear and repeatable task, write it as a question to make it easier for your team to provide information. Write it with the intention of a SMART (i.e., specific, measurable, achievable, relevant, timely) outcome.') }}
    <br>
    {{ t('For example:') }}
    <ul class="ml-5 list-disc">
      <li>
        {{ t('What is the total amount of generated hazardous waste?') }}
      </li>
      <li>
        {{ t('What is the total distance driven by all mini cars fueled by petrol?') }}
      </li>
      <li>
        {{ t('What is the total number of recorded cases of work-related employee injuries?') }}
      </li>
      <li>
        {{ t('How many full-time employees are in the age group of over 50 years old?') }}
      </li>
    </ul>
  </p>
</template>
