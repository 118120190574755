<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { boxed } from '@syncedstore/core';
import ChatBubbleIcon from '@heroicons/vue/outline/ChatAltIcon';
import PlusIcon from '@heroicons/vue/solid/PlusIcon';
import { DoubleMaterialityDownloadDocumentEnum } from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import AtEmptyCard from '../../AtEmptyCard.vue';
import { useDefaultTopics, useStore, useStoreMethods } from '../../store';
import { useDocumentDownload } from '../../composables/useDocumentDownload';
import OgDocument, { type UploadedDocument } from '../../OgDocument.vue';
import OgTable from '../../OgTable.vue';
import MlSubTopicSelect from './MlSubTopicSelect.vue';
import MlSubSubTopicSelect from './MlSubSubTopicSelect.vue';
import OgExplanationDialog from './OgExplanationDialog.vue';

type RowItem = {
  _id: string;
  index: number;
};

const { t } = useI18n();
const router = useRouter();
const { confirmViaDialog } = useConfirmViaDialog();
const store = useStore();
const {
  addDefaultTopics,
  addRecommendedESRS,
  addTopic,
  changeTopic,
  removeTopic,
  changeSubTopic,
  createSubTopic,
  createSubSubTopic,
  resetStore,
} = useStoreMethods();
const defaultTopics = useDefaultTopics();

const { currentUser } = useCurrentUser();
const addExplanation = ref<number | null>(null);

const showEmptyCard = computed(() => store.value.topics.length === 0);

const generalOverviewDownload = useDocumentDownload(DoubleMaterialityDownloadDocumentEnum.GENERAL_OVERVIEW);
const longListDownload = useDocumentDownload(DoubleMaterialityDownloadDocumentEnum.LONG_LIST);

const headers = computed(() => [
  { text: t('Standard'), value: 'esrs', columnClasses: 'w-[250px]' },
  { text: t('Sub-topic'), value: 'subTopic' },
  { text: t('Sub-sub-topic'), value: 'subSubTopic' },
  { text: t('Time horizon'), value: 'timeHorizon', columnClasses: 'w-[150px]' },
  { text: t('Value chain'), value: 'valueChain', columnClasses: 'w-[150px]' },
  { text: '', value: 'explanation', columnClasses: 'w-[120px]' },
]);
const items = computed<RowItem[]>(() => store.value.topics.map((topic, index) => ({
  _id: topic._id,
  index,
})));

const topicOptions = computed(() => defaultTopics.value.reduce<Record<string, string>>(
  (acc, topic) => {
    acc[topic.topic] = `${topic.esrs} ${topic.topic}`;
    return acc;
  },
  {},
));

const timeHorizonOptions = computed(() => ({
  shortTerm: t('Short-term'),
  mediumTerm: t('Medium-term'),
  longTerm: t('Long-term'),
}));

const valueChainOptions = computed(() => ({
  upstream: t('Upstream'),
  ownOperations: t('Own operations'),
  downstream: t('Downstream'),
}));

watch(items, () => {
  addExplanation.value = null;
});

function handleStartAssessmentClick() {
  if (store.value.topics.length === 0) {
    addDefaultTopics();
    addRecommendedESRS();
  }
}

function handleTopicChange(index: number, event: string) {
  changeTopic(index, event);
}

function handleSubTopicChange(index: number, event: string) {
  changeSubTopic(index, event);
}

function handleSubTopicCreated(index: number, event: string) {
  createSubTopic(index, event);
}

function handleSubSubTopicCreated(index: number, event: string) {
  createSubSubTopic(index, event);
}

function handleExplanationDialogClose() {
  addExplanation.value = null;
}

function handleExplanationDialogSave(event: string) {
  if (addExplanation.value !== null && store.value.topics[addExplanation.value]) {
    store.value.topics[addExplanation.value].comment = event;
    handleExplanationDialogClose();
  }
}

function handleExplanationClick(index: number) {
  addExplanation.value = index;
}

function handleRemoveRow(index: number) {
  removeTopic(index);
}

function handleAddRowClick(index: number) {
  addTopic(index);
}

function handleFileChange(index: number, event: UploadedDocument) {
  store.value.topics[index].document = boxed(event);
}

async function handleResetAllClick() {
  const isConfirmed = await confirmViaDialog({
    title: t('Are you sure you wish to reset all data?'),
    text: `<p>${t('By accepting to reset all data, you will loose any progress on your double materiality assessment. This means: any topics, impacts, risks and opportunities, as well as any rating of these, will be lost. You will be lead back to initial screen of the double materiality assessment.')}</p><br /><p>${t('Do you wish to reset all data?')}</p>`,
    renderHTML: true,
    confirmLabel: t('Yes, reset all data'),
    cancelLabel: t('No, go back'),
  });

  if (isConfirmed) {
    resetStore();
  }
}
</script>

<template>
  <AtEmptyCard
    v-if="showEmptyCard"
    variant="default"
    :icon="ChatBubbleIcon"
    :title="t('Start your assessment here by selecting your topics')"
    :primaryAction="{
      label: t('Start assessment'),
      onClick: handleStartAssessmentClick,
    }"
    :secondaryActions="[
      { label: t('Download full list'), loading: longListDownload.loading.value, onClick: longListDownload.exportFile },
      { label: t('Download guidance'), loading: generalOverviewDownload.loading.value, onClick: generalOverviewDownload.exportFile },
    ]"
  >
    <p class="mb-6">
      {{ t('Every double materiality starts by understanding what topics are relevant to your organization. You can start adding or changing topics on this page - remember that this page will be open until the end of your assessment and you can always revisit your selection.') }}
    </p>
    <p>
      {{ t('If you need support or wish to see how the full list looks like beforehand, you can always download our excel lists and guidance below, and start creating your list afterwards.') }}
    </p>
  </AtEmptyCard>
  <template v-else>
    <OgTable
      tableClass="!border-t-0"
      :headers="headers"
      :items="items"
      @removeRow="handleRemoveRow"
    >
      <template #item-esrs="row: RowItem">
        <MlSelect
          :modelValue="store.topics[row.index].topic"
          :disabled="!!(
            store.topics[row.index].subTopic
            && store.topics[row.index].subSubTopic
            && store.topics[row.index].timeHorizon
            && store.topics[row.index].valueChain.length > 0
          )"
          :options="topicOptions"
          @update:modelValue="handleTopicChange(row.index, $event)"
        />
      </template>
      <template #item-subTopic="row: RowItem">
        <MlSubTopicSelect
          :modelValue="store.topics[row.index].subTopic"
          :subTopics="store.topicsOptions[store.topics[row.index].topic] ?? []"
          @created="handleSubTopicCreated(row.index, $event)"
          @update:modelValue="handleSubTopicChange(row.index, $event)"
        />
      </template>
      <template #item-subSubTopic="row: RowItem">
        <MlSubSubTopicSelect
          v-model="store.topics[row.index].subSubTopic"
          :subTopic="store.topics[row.index].subTopic"
          :subTopics="store.topicsOptions[store.topics[row.index].topic] ?? []"
          @created="handleSubSubTopicCreated(row.index, $event)"
        />
      </template>
      <template #item-timeHorizon="row: RowItem">
        <MlSelect
          v-model="store.topics[row.index].timeHorizon"
          :options="timeHorizonOptions"
        />
      </template>
      <template #item-valueChain="row: RowItem">
        <MlSelect
          v-model="store.topics[row.index].valueChain"
          multiple
          :options="valueChainOptions"
        />
      </template>
      <template #item-explanation="row: RowItem">
        <AtButton
          :icon="PlusIcon"
          :class="{ 'text-gray-500': store.topics[row.index].comment.trim().length > 0 }"
          variant="text"
          @click="handleExplanationClick(row.index)"
        >
          {{ t('Explanation') }}
        </AtButton>
      </template>
      <template #expand="row: RowItem">
        <div class="mb-4 flex space-x-2.5">
          <div>
            <button
              type="button"
              aria-label="add rows"
              class="flex text-gray-400 text-xs space-x-2 hover:text-primary"
              @click.prevent="handleAddRowClick(row.index)"
            >
              <PlusIcon class="h-4" />
              {{ t('Add rows') }}
            </button>
          </div>
          <OgDocument
            :userId="currentUser?._id!"
            :file="(store.topics[row.index].document ?? null) as unknown as UploadedDocument"
            @update:file="handleFileChange(row.index, $event)"
          />
        </div>
      </template>
    </OgTable>
    <div class="mt-auto flex items-center space-x-4 justify-between">
      <div class="flex space-x-3">
        <AtButton
          variant="outline"
          type="button"
          @click="router.push({ name: 'doubleMaterialityStartScalesAndCoreData' })"
        >
          {{ t('Go back') }}
        </AtButton>
        <AtButton
          variant="outline"
          type="button"
          :loading="longListDownload.loading.value"
          @click="longListDownload.exportFile"
        >
          {{ t('Download full list') }}
        </AtButton>
      </div>
      <div class="flex space-x-3">
        <AtButton
          variant="outline"
          type="button"
          @click="handleResetAllClick"
        >
          {{ t('Reset all') }}
        </AtButton>
        <AtButton
          type="button"
          @click="router.push({ name: 'doubleMaterialityIROsImpacts' })"
        >
          {{ t('Next') }}
        </AtButton>
      </div>
    </div>
  </template>

  <OgExplanationDialog
    v-if="addExplanation !== null && store.topics[addExplanation]"
    :initialComment="store.topics[addExplanation].comment"
    @close="handleExplanationDialogClose"
    @save="handleExplanationDialogSave"
  />
</template>
