<script setup lang="ts">
import { computed, inject, onMounted, ref, watch, type ComputedRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLazyQuery, useQuery } from '@vue/apollo-composable';
import uniqBy from 'lodash/uniqBy';
import { useRouter } from 'vue-router';
import { watchOnce } from '@vueuse/core';
import { DownloadIcon } from '@heroicons/vue/outline';
import { PlusIcon } from '@heroicons/vue/solid';
import { formatDateRangeForBE } from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import { getActiveEntity } from '@/utils/services/activeEntity';
import dayjs from '@/lib/dayjs/config';
import useChartProps from '@/components/organisms/OgChart/useChartProps';
import OgDateRangePicker from '@/components/organisms/OgDateRangePicker/OgDateRangePicker.vue';
import OgChart from '@/components/organisms/OgChart/OgChart.vue';
import generateReport from '@/utils/helpers/generateReport';
import chartImg from '@/assets/chart.svg';
import { type TmChartViewQuery, type TmChartViewExportPdfQuery, type TmChartSelectionQuery, type DetailedReportQuery } from '@/__generated__/types';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import MlModal from '@/components/molecules/MlModal.vue';
import OgDataPointTypeSelect from '@/components/organisms/OgDataPointTypeSelect/OgDataPointTypeSelect.vue';
import type { TDataPointItem } from '@/components/pages/PgStatistics/PgResults/types';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import MlDataPointValue from '@/components/molecules/MlDataPointValue.vue';
import { normalizeChartData } from '@/utils/helpers/charts/normalizeChartData';
import { groupDataPointsByProject } from '@/utils/helpers/datapoints/groupDatapointsByProject';
import { normalizeDatapointsByType } from '@/utils/helpers/datapoints/normalizeDatapoints';
import { calculateHeaders } from '@/utils/helpers/datapoints/tableHeaders';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import OgDateRangeInput from '@/components/organisms/OgDateRangeInput.vue';
import TM_CHART_SELECTION_QUERY, { REPORT_QUERY } from './TmChartSelection.query';
import TM_CHART_VIEW_EXPORT_PDF_QUERY from './TmChartView.exportPdf.query';
import TM_CHART_VIEW_QUERY from './TmChartView.query';

const { t } = useI18n();
const router = useRouter();
const { currentUser } = useCurrentUser();
const adminMode = inject('adminMode', computed(() => false));
const isModalRevealed = ref(false);
const selectedDataPointTypeName = ref('');

const { result, loading: queryLoading, refetch } = useQuery<TmChartViewQuery>(TM_CHART_VIEW_QUERY, {
  adminMode: adminMode.value,
});
const { result: resultPdfQuery,
  load: loadPdf,
  loading: exportAsPdfLoading } = useLazyQuery<TmChartViewExportPdfQuery>(TM_CHART_VIEW_EXPORT_PDF_QUERY);

const { result: tmChartSelection, loading: tmChartSelectionLoading } = useQuery<TmChartSelectionQuery>(TM_CHART_SELECTION_QUERY, () => ({
  dataPointTypeNames: [selectedDataPointTypeName.value ?? ''],
  allEntities: !!adminMode.value,
  isAdmin: !!adminMode.value,
}), { fetchPolicy: 'network-only' }, // needed because in approval we're also fetching the data, otherwise it won't work because of cache
);
const chartSelectionDataPoints = computed(() => adminMode.value
  ? tmChartSelection.value?.getAdminDataPointsByType ?? []
  : tmChartSelection.value?.getDataPointsByType ?? [],
);
const esgDataPointsLength = computed(() => tmChartSelection.value?.getEsgDataPointsLength);
const isAnyDataPointApprovedAndNumeric = computed(() => result.value?.isAnyDataPointApprovedAndNumeric);

const reportQueryVariables = computed(() => ({
  dataPointTypeNames: [selectedDataPointTypeName.value ?? ''],
  ...formatDateRangeForBE(chartDateRange.value.from, chartDateRange.value.to),
}));

const {
  result: resultReport,
  load: fetchDetailedReport,
} = useLazyQuery<DetailedReportQuery>(REPORT_QUERY, null, { fetchPolicy: 'network-only' });

const chartData = computed(() => {
  const { selectedDataPointType, kpisOfDataPointType } = normalizeChartData({
    availableDataPointsGroupedByType: tmChartSelection.value?.getAvailableDataPointsGroupedByType ?? [],
    selectedDataPointTypeName: selectedDataPointTypeName.value,
    kpis: kpis.value,
  });

  return getChartProps(chartSelectionDataPoints.value, selectedDataPointType, kpisOfDataPointType);
});

onMounted(refetch);

const pinnedDataPointTypes = computed(() => {
  if (adminMode.value) {
    return uniqBy(result.value?.getPinnedDataPointsAdmin ?? [], 'dataPointType.name');
  }
  return result.value?.getOwnUser.pinnedDataPointTypes.filter(
    (pinnedDataPointType) => pinnedDataPointType.entity?._id === getActiveEntity()) ?? [];
},
);
const kpis = computed(() => {
  if (adminMode.value) {
    return tmChartSelection.value?.getCurrentUserEntities.flatMap((e) => e.kpis) ?? [];
  }
  return tmChartSelection.value?.getOwnUser.entity.kpis ?? [];
});

const { chartDateRange, getChartProps } = adminMode.value ? useChartProps({ adminType: 'overview' }) : useChartProps();
const charts = computed(() => pinnedDataPointTypes.value.map((pinnedDataPointType) => {
  let dataPoints: TmChartViewQuery['getPinnedDataPoints' | 'getPinnedDataPointsAdmin'];

  if (adminMode.value) {
    dataPoints = (result.value?.getPinnedDataPointsAdmin?.filter(({ dataPointType: { name } }) => {
      const isBelongToChartDataPointType = name === pinnedDataPointType.dataPointType.name;
      return isBelongToChartDataPointType;
    }) ?? []).map((d) => ({
      ...d,
      location: {
        _id: d.entity?._id ?? '',
        name: d.entity?.name ?? '',
      },
    })).filter(({ location }) => !selectedWorkspace.value.length || selectedWorkspace.value.includes(String(location.name)));
  } else {
    dataPoints = result.value?.getPinnedDataPoints?.filter(({ location, dataPointType: { name } }) => {
      const isBelongToSelectedLocation = !selectedLocation.value.length || selectedLocation.value.includes(location?.name ?? '');
      const isBelongToChartDataPointType = name === pinnedDataPointType.dataPointType.name;
      return isBelongToChartDataPointType && isBelongToSelectedLocation;
    }) ?? [];
  }
  const kpisOfDataPointType = kpis.value.filter((kpi) => kpi.dataPointType._id === pinnedDataPointType.dataPointType._id);

  return getChartProps(dataPoints, pinnedDataPointType.dataPointType, kpisOfDataPointType);
}));

const locationOptions = computed(() => result.value?.getOwnUser.entity.locations
  .reduce<Record<string, string>>((acc, { name }) => ({ ...acc, [name]: name }), {}) ?? {});
const workspaceOptions = computed(() => result.value?.getCurrentUserEntities
  ?.reduce<Record<string, string>>((acc, { name }) => ({ ...acc, [String(name)]: String(name) }), {}) ?? {});

const selectedLocation = ref<string[]>([]);
const selectedWorkspace = ref<string[]>([]);

const exportAsPdf = async () => {
  watchOnce(resultPdfQuery, () => {
    const date = dayjs().format('DD-MM-YYYY');
    let name = currentUser.value?.entity.name ?? '';
    if (name !== '') { name += '_'; }

    const linkSource = `data:application/pdf;base64,${resultPdfQuery.value?.getGraphsPDF}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = `${name}Dashboard_${date}.pdf`;
    downloadLink.click();
  });

  loadPdf(undefined, {
    charts: {
      charts: charts.value.map((chart) => ({ ...chart, title: t(chart.title) })),
      chartDateRange: [chartDateRange.value.from.valueOf(), chartDateRange.value.to.valueOf()],
    },
  });
};
const dataPointsEncodedReport = computed(() => resultReport.value?.getDataPointsEncodedReport ?? '');

function downloadReport() { fetchDetailedReport(REPORT_QUERY, reportQueryVariables.value); }
watch(dataPointsEncodedReport, () => generateReport(
  dataPointsEncodedReport.value,
  currentUser.value?.lastName,
  reportQueryVariables.value.dataPointTypeNames,
));

const items: ComputedRef<TDataPointItem[]> = computed(
  () => normalizeDatapointsByType({
    chartSelectionDataPoints: chartSelectionDataPoints.value,
    isAdmin: adminMode.value,
  }),
);

</script>

<template>
  <div class="pt-5 px-6">
    <div class="sticky top-0 bg-white z-50 pb-4">
      <div class="flex">
        <div class="flex grow flex-col">
          <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate mb-3">
            {{ t('Dashboard') }}
          </h1>
          <p class="text-gray-500 mt-4 mb-6 text-sm">
            {{ t('Add charts to compare your workspaces and view their statistics.') }}
          </p>
        </div>
        <div class="flex items-start">
          <AtButton
            variant="outline"
            class="ml-auto"
            :icon="DownloadIcon"
            :loading="exportAsPdfLoading"
            @click="exportAsPdf"
          >
            {{ t('Download PDF') }}
          </AtButton>
          <AtButton
            class="ml-4"
            :icon="PlusIcon"
            @click="() => { isModalRevealed = true }"
          >
            {{ t('Add') }}
          </AtButton>
        </div>
      </div>
      <div
        v-if="result?.getOwnUser?.pinnedDataPointTypes?.length"
        class="flex flex-wrap items-end gap-2"
      >
        <MlSelect
          v-if="!adminMode"
          v-model="selectedLocation"
          class="w-64"
          :title="t('Project')"
          :placeholder="t('All projects')"
          :options="locationOptions"
          multiple
        />
        <MlSelect
          v-if="adminMode"
          v-model="selectedWorkspace"
          class="w-64"
          :title="t('Workspace')"
          :placeholder="t('All workspaces')"
          :options="workspaceOptions"
          multiple
        />
        <div class="ml-2">
          <OgDateRangeInput v-model="chartDateRange" />
        </div>
      </div>
    </div>

    <MlModal
      class="m-6 min-w-[24rem] max-w-7xl w-full h-full"
      :isRevealed="isModalRevealed"
      @close="() => { isModalRevealed = false }"
    >
      <div class="pt-5 px-6 flex flex-col">
        <h3 class="text-xl font-bold">
          {{ t('Add chart to dashboard') }}
        </h3>

        <p class="mt-3">
          {{ t('Select the topic and datapoint, then click on the ‘Star’ to add the chart to your dashboard.') }}
        </p>
        <hr class="my-4" />
        <div class="mt-4 border border-gray-100 p-4 rounded-md">
          <OgDataPointTypeSelect
            class="col-span-9"
            selectClassWrapper="!w-[244px] text-sm"
            autocompleteClassWrapper="!w-[374px]"
            :withExistingDataPoints="true"
            @update:modelValue="selectedDataPointTypeName = $event?.name || ''"
          />
        </div>
        <template v-if="selectedDataPointTypeName && tmChartSelectionLoading">
          <div class="mt-4 pl-4">
            <span>Loading...</span>
          </div>
        </template>
        <template v-else>
          <template
            v-if="selectedDataPointTypeName && esgDataPointsLength"
          >
            <div class="bg-[rgba(156,163,175,0.05)] mt-6 mb-8">
              <OgChart
                v-bind="chartData"
                data-cy="OgChart"
                :showStarIcon="true"
                class="bg-gray-200/25 max-w-[520px]"
              >
                <template #menuItems>
                  <OgDateRangePicker
                    v-model="chartDateRange"
                    class="flex w-full p-4"
                    data-cy="OgDateRangePicker"
                    inline
                  />

                  <AtButton
                    variant="link"
                    class="w-full py-2"
                    :icon="DownloadIcon"
                    data-cy="AtButtonDownloadReport"
                    @click.stop="downloadReport"
                  >
                    {{ t('Download as Excel') }}
                  </AtButton>
                </template>
              </OgChart>
            </div>
          </template>
          <OgDataTable
            v-if="selectedDataPointTypeName && chartSelectionDataPoints.length > 0"
            :items="groupDataPointsByProject({ dataPointsByType: chartSelectionDataPoints, items })"
            :headers="calculateHeaders({ items, isAdminMode: adminMode })"
            controlsHidden
            secondStyle
            :hasTotal="true"
          >
            <template
              v-for="item in items"
              :key="item.header.value"
              #[`item-${item.header.value}`]="dp"
            >
              <MlDataPointValue
                v-if="dp[item.header.value]"
                :dataPointValueAndType="{
                  ...dp[item.header.value],
                  ...(dp[item.header.value].displayValue ? { value: dp[item.header.value].displayValue } : {}),
                  ...(dp[item.header.value].displayValueUnit ? { valueUnit: dp[item.header.value].displayValueUnit } : {}),
                  ...(dp[item.header.value].displayValueUnit
                    ? {
                      dataPointType: {
                        ...dp[item.header.value].dataPointType, valueUnit: dp[item.header.value].displayValueUnit,
                      },
                    } : {}),
                }"
              />
            </template>
          </OgDataTable>
        </template>
      </div>
    </MlModal>

    <MlEmptyStateCard
      v-if="!isAnyDataPointApprovedAndNumeric && !queryLoading"
      :title="t('No data yet')"
      :description="t('Start by entering data and assigning to team members.')"
      :buttonText="t('Enter data')"
      @buttonClick="router.push({ name: 'projects' })"
    >
      <img
        class="mb-7"
        :src="chartImg"
        alt="Graph"
      >
    </MlEmptyStateCard>
    <MlEmptyStateCard
      v-if="!charts.length && isAnyDataPointApprovedAndNumeric && !queryLoading"
      :title="t('No charts yet')"
      :description="adminMode
        ? t('Add charts and key sustainability metrics to compare your workspaces.')
        : t('Create a dashboard panel to display sustainability information you are collecting with Codio Impact. Select and add your favourite charts to this dashboard.')"
      :buttonText="t('Select graphs')"
      @buttonClick="() => {
        isModalRevealed = true
      }"
    >
      <img
        class="mb-7"
        :src="chartImg"
        alt="Graph"
      >
    </MlEmptyStateCard>

    <div
      v-if="charts.length"
      class="inline-flex flex-wrap gap-10"
    >
      <OgChart
        v-for="({ dataPointTypeName, title, ...chartProps }) in charts"
        :key="dataPointTypeName"
        class="w-[520px]"
        v-bind="chartProps"
        :title="title"
        showStarIcon
      />
    </div>
  </div>
</template>
