import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { UpdateTaxonomyCoreDataMutation, UpdateTaxonomyCoreDataMutationVariables } from '@/__generated__/types';

export const UPDATE_TAXONOMY_CORE_DATA = gql`
  mutation UpdateTaxonomyCoreData($input: TaxonomyCoreDataInput!) {
    updateTaxonomyCoreData(coreData: $input) {
      _id
      turnover
      turnoverFile {
        _id
        file {
          _id
          filename
          downloadUrl
        }
      }
      capEx
      capExFile {
        _id
        file {
          _id
          filename
          downloadUrl
        }
      }
      opEx
      opExFile {
        _id
        file {
          _id
          filename
          downloadUrl
        }
      }
    }
  }
`;

type TOptions = UseMutationOptions<UpdateTaxonomyCoreDataMutation, UpdateTaxonomyCoreDataMutationVariables>

const useUpdateTaxonomyCoreDataMutation = (options?: TOptions) => useMutation<
  UpdateTaxonomyCoreDataMutation,
  UpdateTaxonomyCoreDataMutationVariables
>(UPDATE_TAXONOMY_CORE_DATA, options);

export default useUpdateTaxonomyCoreDataMutation;
