import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';

import type { CreateTaxonomyProjectMutation, CreateTaxonomyProjectMutationVariables } from '../../../__generated__/types';

const CREATE_TAXONOMY_PROJECT_MUTATION = gql`
  mutation CreateTaxonomyProject($name: String!, $assignee: String!) {
    createTaxonomyProject(name: $name, assignee: $assignee) {
      _id
      name
    }
  }
`;

type TOptions = UseMutationOptions<CreateTaxonomyProjectMutation, CreateTaxonomyProjectMutationVariables>

const useCreateTaxonomyProjectMutation = (options?: TOptions) => useMutation<
  CreateTaxonomyProjectMutation,
  CreateTaxonomyProjectMutationVariables
>(CREATE_TAXONOMY_PROJECT_MUTATION, options);

export default useCreateTaxonomyProjectMutation;
