<script setup lang="ts">
import { XIcon } from '@heroicons/vue/solid';
import { CheckCircleIcon } from '@heroicons/vue/outline';
import { useI18n } from 'vue-i18n';
import { ReportingFrameworkEnum, type PgExternalDataEntryQuery } from '@/__generated__/types';
import AtDataPointRequestDate from '@/components/atoms/AtDataPointRequestDate.vue';
import AtBadge from '@/components/atoms/AtBadge.vue';
import AtTooltipIcon from '@/components/atoms/AtTooltipIcon.vue';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import type { DataPointRequestWithValueSourceNames } from '../PgProjects/types';
import MlExternalQuestionForm from './MlExternalQuestionForm.vue';

const { t } = useI18n();

const props = defineProps<Props>();
type Props = {
  dpr: PgExternalDataEntryQuery['getDelegatedDataPointRequests'][number]
  index: number
  isOpen: boolean
  dataPointRequestsWithValueSourceNames: DataPointRequestWithValueSourceNames[]
  currentExternalUserId?: PgExternalDataEntryQuery['getCurrentExternalUserId']
}

const emit = defineEmits(['toggle']);

</script>

<template>
  <div class="hover:bg-gray-50">
    <p
      class="cursor-pointer text-gray-500"
      @click.stop="emit('toggle')"
    >
      <AtDataPointRequestDate
        class="order-1 col-span-4 2xl:col-span-1"
        :from="dpr.from"
        :to="dpr.to"
      />:
      <span>
        {{ props.dpr.location.name }}
      </span>
    </p>
    <p class="inline-flex h-full max-w-3xl items-center">
      <CheckCircleIcon v-if="props.dpr.value" class="w-5 text-primary mr-2" />
      <span
        class="w-full cursor-pointer"
        @click.stop="emit('toggle')"
      >
        <span class="mr-2 whitespace-nowrap">
          <span
            class="whitespace-pre-line group-hover:text-primary"
          >
            {{ t(dpr.dataPointType.question) }}
          </span>&#65279;
          <AtTooltipIcon
            v-if="dpr.dataPointType.questionHelp"
            :triggers="['click', 'touch']"
            :delay="0"
            autoHide
            class="cursor-pointer"
            @click.stop
          >
            <template #tooltip="{ hide }">
              <div class="flex flex-row-reverse whitespace-pre-line">

                <AtIconButton
                  class="h-6 w-6 shrink-0"
                  :icon="XIcon"
                  :title="t('Close')"
                  @click="hide"
                />
                <div>
                  <template
                    v-if="dpr.dataPointType.reportingFrameworks
                      .find((reportingFramework) => reportingFramework.framework === ReportingFrameworkEnum.CUSTOM)"
                  >
                    <MlHtmlContent :html="dpr.dataPointType.questionHelp" />
                  </template>
                  <template v-else>
                    {{ t(dpr.dataPointType.questionHelp) }}
                  </template>
                </div>
              </div>
            </template>
          </AtTooltipIcon>
        </span>
      </span>
    </p>
    <div
      v-show="props.isOpen"
      class="grid grid-cols-[1fr] items-start justify-between justify-items-start gap-4 mx-2"
    >
      <div class="flex w-full flex-col items-start text-gray-400">
        <!-- <AtDataPointRequestDate
          class="order-1 col-span-4 2xl:col-span-1"
          :from="dpr.from"
          :to="dpr.to"
        /> -->
        <AtBadge
          v-if="dpr.isHistoric"
          type="neutral"
        >
          {{ t('Past data') }}
        </AtBadge>
      </div>
      <MlExternalQuestionForm
        :dataPointRequest="dpr"
        :dataPointRequestsWithValueSourceNames="props.dataPointRequestsWithValueSourceNames"
        :isGroup="false"
        :currentExternalUserId="props.currentExternalUserId"
      />
    </div>
    <slot />
  </div>
</template>
