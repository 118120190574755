<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { TTargetCommon } from '../../../types';

const props = defineProps<{
  modelValue?: TTargetCommon,
  options: TTargetCommon[],
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', goal?: TTargetCommon): void
}>();

const { t } = useI18n();

</script>

<template>
  <div class="w-full divide-y">
    <label
      v-for="option in props.options"
      :key="option._id"
      class="grid grid-flow-col grid-cols-[0fr_0fr_1fr_0fr] items-center gap-6 p-4 text-sm"
    >
      <input
        type="radio"
        class="radio-primary radio"
        :checked="props.modelValue?._id === option._id"
        :value="option._id"
        @input="emit('update:modelValue', option)"
      >
      <p>
        <template v-if="option.number">{{ option.number }}</template>
      </p>
      <p>
        {{ t(option.name) }}
        <br>
        <span v-if="!option.suggestedDataPointTypes.length" class="text-gray-500 text-xs">
          {{ t("This target does not match any of the system's preset datapoints, hence it can only be connected to your custom datapoints.") }}
        </span>
      </p>
      <div class="w-36">
        <span
          v-if="option.isRecommended"
          class="inline-flex items-center justify-center rounded bg-gray-200 px-2 py-1 text-sm font-bold text-gray-400"
        >
          {{ t('Recommended') }}
        </span>
      </div>
    </label>
  </div>
</template>
